import React from 'react';
import moment from 'moment';
import config from '../config';

const base64value = config.supportBase64value;

function Billing({ user, membershipCurrent }) {

    return (

        <>

            {
                (
                    !membershipCurrent ||
                    !membershipCurrent.length
                ) &&
                <>

                    <span className="hr"></span>

                    <div className="recap">

                        <p>Your current membership is:</p>
                        <h2>Free</h2>
                        <h3>Forever</h3>
                        <p>It will expire on.. <b>never!!</b></p>

                    </div>

                </>
            }

            {
                membershipCurrent &&
                membershipCurrent.length > 0 &&
                membershipCurrent.map((el, index) =>
                    <React.Fragment key={index}>

                        <span className="hr"></span>

                        <div className={`recap` + ((index === 0) ? ' recap--active' : '')}>

                            {index === 0 && <p>Your current membership is:</p>}
                            <h2>{el.label}</h2>
                            <h3>{el.accountTime}</h3>
                            <p>{index === 0 ? 'it started on' : 'it will start on'} {moment.unix(el.timeStart).format('DD/MM/YYYY')} and it will finish on {moment.unix(el.timeEnd).format('DD/MM/YYYY')}</p>

                        </div>

                    </React.Fragment>
                )
            }

            <span className="hr"></span>

            <p>We do our best to make the system easy to use, but in case you have any questions or notice any issues, do not hesitate to email us at <a href={`mailto:${base64value}`}>{base64value}</a>.</p>

        </>

    );
}

export default Billing;