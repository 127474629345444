import http from "./httpService";
// import qs from 'querystring'
import config from '../config';

export function cancelToken() {
    return http.CancelToken.source();
}

export async function refresh({ token, request }) {
    return new Promise(
        async (resolve, reject) => {
            try {
                const response = await http({
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'authorization': token },
                    url: config.backendUrl + config.endpoints.refreshUser,
                    cancelToken: request.token
                });
                if (response.data) return resolve(response.data);
                return reject();
            } catch (err) { return reject({ err, isCancel: http.isCancel(err) }); }
        }
    );
}

// eslint-disable-next-line
export default {
    cancelToken,
    refresh
};
