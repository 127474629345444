import React, { useState, useEffect, useCallback, useContext } from 'react';
import checkNested from '../utils/checkNested';
import validateEmail from '../utils/validateEmail';
import { Redirect } from 'react-router-dom';
import defaultErrorMessage from '../utils/defaultErrorMessage';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

// services
import service from "../services/loginService";

// utils
// import storage from '../utils/storage'

function Popup({ state, dispatch }) {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function handleLogin(e) {

        e.preventDefault();

        async function fetchData() {

            const request = service.cancelToken();

            setIsLoading(true);

            try {

                const response = await service.login({ email, password, request });

                // setTimeout(() => {
                setIsLoading(false);
                // }, 500);

                if (!checkNested(response, 'data', 'token')) return appDispatch({
                    type: "alertOpen",
                    ico: "error",
                    value: "Invalid credentials"
                });

                appDispatch({ type: "setJustLogged" });
                appDispatch({ type: "setToken", data: response.data.token });

                // setTimeout(() => {
                appDispatch({ type: 'setPopupLogin', data: false });
                // }, 500);

                // <Redirect to={'/'} />
                // window.history.pushState(null, null, '/');

            } catch (e) {

                if (e.isCancel !== true) {
                    appDispatch({
                        type: "alertOpen",
                        ico: "error",
                        value: defaultErrorMessage(e)
                    });
                    setIsLoading(false);
                }

            }
        }

        if (!validateEmail(email)) return appDispatch({ type: "alertOpen", ico: "error", value: 'Email not valid' });
        if (password.length < 10) return appDispatch({ type: "alertOpen", ico: "error", value: 'Password not valid' });

        fetchData();

    }

    const handleClose = useCallback(() => {

        dispatch({ type: 'setPopupLogin', data: false });

    }, [dispatch]);

    useEffect(() => {

        function keyPressHandler(e) {
            if (e.keyCode === 27) {
                handleClose();
            }
        }

        document.addEventListener("keyup", keyPressHandler);
        return () => { document.removeEventListener("keyup", keyPressHandler); }

    }, [handleClose]);

    if (appState.token) return <Redirect to={'/'} />

    return (

        <>

            <button onClick={(e) => handleClose()} className="btn btn--close">.</button>

            <div className="box">

                <div className="logo"></div>

                <form>

                    <input onChange={(e) => setEmail(e.target.value)} type="email" value={email} placeholder="Email" />
                    <input onChange={(e) => setPassword(e.target.value)} type="password" value={password} placeholder="Password" />

                    <button className={`btn` + (isLoading ? ' btn--loading' : '')} onClick={handleLogin} disabled={isLoading} type="submit"><span>Sign In</span><span className="loading"></span></button>

                </form>

                <small>
                    <button onClick={(e) => {
                        appDispatch({ type: 'setPopupLogin', data: false });
                        appDispatch({ type: 'setPopupRegister', data: true });
                    }
                    }>Don't have an account yet?</button> What are you waiting for?
                    {/* <br /><a href="#">Having issues logging in?</a> */}
                </small>

            </div>

        </>

    );
}

export default Popup;
