import React, { useEffect, useCallback, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import checkNested from '../utils/checkNested';
import validateEmail from '../utils/validateEmail';
import defaultErrorMessage from '../utils/defaultErrorMessage';

// context
import DispatchContext from "../context/DispatchContext";

// services
import service from "../services/registerService";

// utils
// import storage from '../utils/storage'

function Popup({ state, dispatch }) {

    const appDispatch = useContext(DispatchContext);

    const [isLoading, setIsLoading] = useState(false);

    const [username, setUsername] = useState('');
    const [usernameLoading, setUsernameLoading] = useState(false);
    const [usernameError, setUsernameError] = useState('');
    const [usernameCounter, setUsernameCounter] = useState(0);

    const [email, setEmail] = useState('');
    const [emailLoading, setEmailLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [emailCounter, setEmailCounter] = useState(0);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleClose = useCallback(() => {

        dispatch({ type: 'setPopupRegister', data: false });

    }, [dispatch]);

    function inputControl({ request, value, valueLabel, setError, setLoading, serviceLabel }) {
        setLoading(true);
        async function fetchResults() {
            try {
                await service[serviceLabel]({ [valueLabel]: value, request });
                setError('');
            } catch (e) {
                const error = defaultErrorMessage(e);
                setError(error)
            } finally {
                setTimeout(function () { setLoading(false); }, 500);
            }
        }
        fetchResults();
    }

    useEffect(() => {
        const request = service.cancelToken();
        if (usernameCounter) {
            inputControl({
                request,
                value: username,
                valueLabel: 'username',
                setError: setUsernameError,
                setLoading: setUsernameLoading,
                serviceLabel: 'checkUsername'
            });
            return () => { request.cancel(); }
        }
        // eslint-disable-next-line
    }, [usernameCounter]);

    useEffect(() => {

        if (username.length) {
            const regex = /^\w+$/;
            const delay = setTimeout(() => {
                if (username.length <= 3) { setUsernameError('Too short') }
                else if (!regex.exec(username)) { setUsernameError('Only alphanumeric characters') }
                else { setUsernameCounter(usernameCounter + 1); }
            }, 800)
            return () => clearTimeout(delay)
        } else { setUsernameError(''); }
        // eslint-disable-next-line
    }, [username]);

    useEffect(() => {
        const request = service.cancelToken();
        if (emailCounter) {
            inputControl({
                request,
                value: email,
                valueLabel: 'email',
                setError: setEmailError,
                setLoading: setEmailLoading,
                serviceLabel: 'checkEmail'
            });
            return () => { request.cancel(); }
        }
        // eslint-disable-next-line
    }, [emailCounter]);

    useEffect(() => {
        if (email.length) {
            const delay = setTimeout(() => {
                if (!validateEmail(email)) { setEmailError('Email not valid') }
                else { setEmailCounter(emailCounter + 1); }
            }, 800)
            return () => clearTimeout(delay)
        } else { setEmailError(''); }
        // eslint-disable-next-line
    }, [email]);

    function handleRegister(e) {

        e.preventDefault();

        async function fetchData() {

            const request = service.cancelToken();

            setIsLoading(true);

            try {

                const response = await service.register({ email, username, password, request });

                setTimeout(() => { setIsLoading(false); }, 500);

                if (!checkNested(response, 'data', 'registered')) return appDispatch({
                    type: "alertOpen",
                    ico: "error",
                    value: "Invalid data"
                });
                else {
                    handleClose();
                    return appDispatch({
                        type: "alertOpen",
                        ico: "mail",
                        value: "Please confirm your email in the next 20 minutes"
                    });
                    // appDispatch({ type: "setToken", data: response.data.token });
                    // storage.set('user', response.data.user);
                    // storage.set('gallery', response.data.gallery);
                    // setTimeout(() => { appDispatch({ type: 'setPopupLogin', data: false }); }, 500);
                }

            } catch (e) {

                if (e.isCancel !== true) {
                    appDispatch({
                        type: "alertOpen",
                        ico: "error",
                        value: defaultErrorMessage(e)
                    });
                    setIsLoading(false);
                }

            }

        }

        if (username.length < 4) return appDispatch({ type: "alertOpen", ico: "error", value: 'Username too short, minimum 4 characters' });
        if (usernameError) return appDispatch({ type: "alertOpen", ico: "error", value: 'Username not available' });

        if (!validateEmail(email)) return appDispatch({ type: "alertOpen", ico: "error", value: 'Email not valid' });
        if (emailError) return appDispatch({ type: "alertOpen", ico: "error", value: 'Email not available' });

        if (password.length < 10) return appDispatch({ type: "alertOpen", ico: "error", value: 'Password too short, minimum 10 characters' });
        if (password !== confirmPassword) return appDispatch({ type: "alertOpen", ico: "error", value: 'Confirmation Password doesn\'t match' });

        if (
            username.length &&
            email.length &&
            password.length &&
            confirmPassword.length
        ) {

            fetchData();

        }

    }

    useEffect(() => {

        function keyPressHandler(e) {
            if (e.keyCode === 27) {
                handleClose();
            }
        }

        document.addEventListener("keyup", keyPressHandler);
        return () => { document.removeEventListener("keyup", keyPressHandler); }

    }, [handleClose]);

    return (

        <>

            <button onClick={(e) => handleClose()} className="btn btn--close">.</button>

            <div className="box">

                <div className="logo"></div>

                <form>

                    <div className={"input-container" + (usernameLoading ? ' input-loading' : '')}>
                        <input onChange={(e) => setUsername(e.target.value)} type="text" value={username} placeholder="Username" />
                        {usernameError && <div className="input-error">{usernameError}</div>}
                    </div>

                    <div className={"input-container" + (emailLoading ? ' input-loading' : '')}>
                        <input onChange={(e) => setEmail(e.target.value)} type="email" value={email} placeholder="Email" />
                        {emailError && <div className="input-error">{emailError}</div>}
                    </div>

                    <input onChange={(e) => setPassword(e.target.value)} type="password" value={password} placeholder="Password" />
                    <input onChange={(e) => setConfirmPassword(e.target.value)} type="password" value={confirmPassword} placeholder="Confirm Password" />

                    <button className={`btn` + (isLoading ? ' btn--loading' : '')} onClick={handleRegister} disabled={isLoading} type="submit"><span>Sign Up</span><span className="loading"></span></button>

                </form>

                <small>
                    by joining MuseoBit you are agreeing to our <Link onClick={handleClose} to={"privacy-policy"}>Privacy Policy</Link>, <Link onClick={handleClose} to={"cookie-policy"}>Cookie Policy</Link> and <Link onClick={handleClose} to={"terms-of-use"}>Terms of Use</Link>.<br />
                    Do have an account? <button onClick={(e) => {
                        dispatch({ type: 'setPopupLogin', data: true });
                        dispatch({ type: 'setPopupRegister', data: false });
                    }
                    }>Sign in</button>.
                </small>

            </div>

        </>

    );
}

export default Popup;
