import React, { useState, useContext } from 'react';
import defaultErrorMessage from '../utils/defaultErrorMessage';
import checkNested from '../utils/checkNested';
import miniAlert from '../utils/miniAlert';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

// services
import serviceSaveGenericString from "../services/saveGenericStringService";

function Museum({ data, setData, unsaved, setUnsaved }) {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const [isLoading, setIsLoading] = useState(false);

    const [localData, setLocalData] = useState(data.about || '');
    const [firstLoad, setFirstLoad] = useState(false);

    const token = appState.token;

    function handleSubmit(e) {

        e.preventDefault();

        async function fetchData() {
            const request = serviceSaveGenericString.cancelToken();
            setIsLoading(true);
            try {
                await serviceSaveGenericString.save({ token, content: localData, type: 'about', request });
                setTimeout(() => { setIsLoading(false); }, 500);
                setUnsaved(false);

                const updateData = { ...data, about: localData };
                setData(updateData);

                setFirstLoad(true);
                miniAlert({
                    overflow: false,
                    autoremove: true,
                    size: 'medium',
                    text: 'Saved!'
                });
            } catch (e) {
                if (e.isCancel !== true) {
                    if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                    appDispatch({
                        type: "alertOpen",
                        ico: "error",
                        value: defaultErrorMessage(e)
                    });
                    setIsLoading(false);
                }
            }
        }
        fetchData();

    }

    return (
        <form className="form" onSubmit={handleSubmit}>

            <label className="label-container">
                <textarea
                    style={{ minHeight: '240px' }}
                    // placeholder="Lorem ipsum.."
                    placeholder={
                        `#This is a title
*This text is italic*
**This text is bold**`
                    }
                    onChange={(e) => {
                        const content = e.target.value.length <= 512 ? e.target.value : e.target.value.substring(0, 512);
                        setLocalData(content);
                        setUnsaved(true);
                    }}
                    value={localData}
                ></textarea>
                <div className="label"><span>About your museum.. <b style={{ float: 'right' }}>{localData.length}<small> / 512</small></b></span></div>
            </label>

            {/* <p className="monospace" style={{ textAlign: 'center' }}><small># This text will be a title #<br />* This text will be italic *<br />** This text will be bold **</small></p> */}

            <span className="hr"></span>

            <button disabled={!unsaved && firstLoad} type="submit" className={'btn btn--center btn--large' + (unsaved || firstLoad ? ' btn--callToAction' : '') + (isLoading ? ' btn--loading' : '')}>Save<span className="loading"></span></button>

        </form>
    );
}

export default Museum;