import React, { useState, useEffect, useRef, useContext } from 'react';
import config from '../config';
import { loadStripe } from "@stripe/stripe-js";
import defaultErrorMessage from '../utils/defaultErrorMessage';
import checkNested from '../utils/checkNested';
import moment from 'moment';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

// services
import serviceCoupon from "../services/couponService";

const selectedCurrency = config.pricing.selectedCurrency;
const base64value = config.supportBase64value;

function Billing({ user, membershipCurrent, triggerCall, setTriggerCall, setBillingType }) {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const token = appState.token;

    const initFocus = useRef(null);

    const [coupon, setCoupon] = useState('');
    const [couponLoading, setCouponLoading] = useState(false);

    const [accountTime, setAccountTime] = useState('Monthly');
    const [accountType, setAccountType] = useState(1);
    const [submitIsLoading, setSubmitIsLoading] = useState(false);

    const [couponPageType, setCouponPageType] = useState('default');
    const [couponPageInfo, setCouponPageInfo] = useState(false);

    useEffect(() => {
        // if (initFocus && initFocus.current) initFocus.current.focus();
    }, []);

    function handleCouponReset(e) {

        e.preventDefault();
        setCoupon('');
        setCouponPageType('default');

    }

    async function handleUpgradeSubmit(e) {

        e.preventDefault();

        const request = serviceCoupon.cancelToken();
        setSubmitIsLoading(true);
        try {
            await serviceCoupon.upgradeMembership({ token, request, coupon });
            setTimeout(() => { setSubmitIsLoading(false); }, 0);

            // const newTriggeredValue = triggerCall + 1;
            // setTriggerCall(newTriggeredValue);
            // setBillingType('Current');
            window.location.reload();

        } catch (e) {
            if (e.isCancel !== true) {
                if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                appDispatch({
                    type: "alertOpen",
                    ico: "error",
                    value: defaultErrorMessage(e)
                });
                setSubmitIsLoading(false);
            }
        }

    }

    async function handleCouponSubmit(e) {

        e.preventDefault();

        if (coupon.length) {

            const request = serviceCoupon.cancelToken();
            setCouponLoading(true);
            try {
                const response = await serviceCoupon.checkCode({ token, request, coupon });
                if (response.data.coupon.type) setCouponPageType(response.data.coupon.type);
                if (response.data.coupon) setCouponPageInfo(response.data.coupon);
                setTimeout(() => { setCouponLoading(false); }, 0);
            } catch (e) {
                if (e.isCancel !== true) {
                    if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                    appDispatch({
                        type: "alertOpen",
                        ico: "error",
                        value: defaultErrorMessage(e)
                    });
                    setCouponLoading(false);
                }
            }

        }

    }

    async function handleSubmit(e) {

        e.preventDefault();

        setSubmitIsLoading(true);

        const stripePromise = loadStripe(config.stripe);
        const stripe = await stripePromise;

        const data = new FormData();
        data.append("accountType", accountType);
        data.append("accountTime", accountTime.toLowerCase());

        try {

            fetch(config.backendUrl + config.endpoints.createCheckoutSession, {
                method: 'POST',
                headers: { 'authorization': token },
                body: data
                // body: `accountType=${accountType}&=accountTime=${accountTime}`
            })
                .then(function (response) { return response.json(); })
                .then(function (session) { return stripe.redirectToCheckout({ sessionId: session.stripe.id }); })
                .then(function (result) {
                    setSubmitIsLoading(false);
                    if (result.error) {
                        appDispatch({
                            type: "alertOpen",
                            ico: "error",
                            value: result.error.message
                        });
                    }
                });

        } catch (e) {

            if (e.isCancel !== true) {
                setSubmitIsLoading(false);
                if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                appDispatch({
                    type: "alertOpen",
                    ico: "error",
                    value: defaultErrorMessage(e)
                });
            }

        }

    }

    return (

        <>

            {couponPageType === 'discount' && <></>}

            {couponPageType === 'free' &&

                <>

                    <span className="hr"></span>

                    <form className="form form--flex" onSubmit={handleCouponReset}>

                        <input type="text" value={coupon} onChange={(e) => setCoupon(e.target.value)} placeholder="Do you have a coupon?" />
                        <button disabled={couponLoading} type="submit" className={`btn` + (couponLoading ? ' btn--loading' : '')}>Reset<span className="loading"></span></button>

                    </form>

                    <span className="hr"></span>

                    <form className="form" onSubmit={handleUpgradeSubmit}>

                        <div className="recap">

                            <h2>{couponPageInfo && couponPageInfo.account && config.accountType.find(el => el.id === couponPageInfo.account).label}</h2>
                            <h3>{couponPageInfo.duration / (60 * 60 * 24)} days</h3>

                            <>
                                {membershipCurrent.length === 0 && <p>it will expire on {moment().add(couponPageInfo.duration, 'seconds').format('DD/MM/YYYY')}</p>}

                                {membershipCurrent.length > 0 &&
                                    <p>it will start on {moment.unix(membershipCurrent[membershipCurrent.length - 1].timeEnd).format('DD/MM/YYYY')} and it will expire on {moment.unix(membershipCurrent[membershipCurrent.length - 1].timeEnd + couponPageInfo.duration).format('DD/MM/YYYY')}</p>
                                }
                            </>

                        </div>

                        <span className="hr"></span>

                        <button disabled={submitIsLoading} type="submit" className={`btn btn--center btn--large btn--callToAction` + (submitIsLoading ? ' btn--loading' : '')}>Upgrade<span className="loading"></span></button>

                    </form>

                </>

            }

            {couponPageType === 'default' &&

                <>

                    {
                        (membershipCurrent && membershipCurrent.length >= 3) &&
                        <>
                            <div className="face-box">
                                <div className="face face--happy"></div>
                                <h3>How many memberships do you need?</h3>
                            </div>
                            <span className="hr"></span>
                            <p>We do our best to make the system easy to use, but in case you have any questions or notice any issues, do not hesitate to email us at <a href={`mailto:${base64value}`}>{base64value}</a>.</p>
                        </>
                    }

                    {
                        (
                            !membershipCurrent ||
                            (membershipCurrent && membershipCurrent.length < 3)
                        ) &&

                        <>

                            <span className="hr"></span>

                            <form className="form form--flex" onSubmit={handleCouponSubmit}>

                                <input type="text" value={coupon} onChange={(e) => setCoupon(e.target.value)} placeholder="Do you have a coupon?" />
                                <button disabled={couponLoading} type="submit" className={`btn` + (couponLoading ? ' btn--loading' : '')}>Apply<span className="loading"></span></button>

                            </form>

                            <span className="hr"></span>

                            <ul className="toggle-widget toggle-widget--v2">
                                <li onClick={(e) => { setAccountTime('Monthly') }} className={accountTime === 'Monthly' ? 'active' : null}>Monthly</li>
                                <li onClick={(e) => { setAccountTime('Annual') }} className={accountTime === 'Annual' ? 'active' : null}>Annual</li>
                            </ul>

                            <form className="form" onSubmit={handleSubmit}>

                                <label className="label-container">
                                    <select
                                        // defaultValue={user.t ? user.t : false}
                                        ref={initFocus}
                                        onChange={(e) => { setAccountType(e.target.value) }}
                                    >
                                        {config.accountType.map((el, index) => {
                                            // if (el.id !== 1)
                                            return <option key={index} value={el.id}>{el.label}</option>
                                            // else
                                            //     return null
                                        }
                                        )}
                                    </select>
                                    <div className="label"><span>Membership</span></div>
                                </label>

                                <div className="recap">

                                    <h2><small>{config.pricing.currencies[selectedCurrency]}</small><span className="number">{config.pricing.table[accountTime.toLowerCase()].find(el => el.id.toString() === accountType.toString()).price[selectedCurrency]}</span></h2>
                                    <h3>{accountTime === 'Annual' ? 'per year' : 'per month'}</h3>

                                    {
                                        accountType &&
                                        accountType.toString() !== '1' &&
                                        <>
                                            {membershipCurrent.length === 0 && <p>it will expire on {accountTime === 'Monthly' ? moment().add(1, 'months').format('DD/MM/YYYY') : moment().add(1, 'years').format('DD/MM/YYYY')}</p>}

                                            {membershipCurrent.length > 0 &&
                                                <p>it will start on {moment.unix(membershipCurrent[membershipCurrent.length - 1].timeEnd).format('DD/MM/YYYY')} and it will expire on {accountTime === 'Monthly' ? moment.unix(membershipCurrent[membershipCurrent.length - 1].timeEnd).add(1, 'months').format('DD/MM/YYYY') : moment.unix(membershipCurrent[membershipCurrent.length - 1].timeEnd).add(1, 'years').format('DD/MM/YYYY')}</p>
                                            }
                                        </>
                                    }

                                </div>

                                <span className="hr"></span>

                                <button disabled={
                                    submitIsLoading ||
                                    !accountType ||
                                    (accountType && accountType.toString() === '1')
                                } type="submit" className={`btn btn--center btn--large btn--callToAction` + (submitIsLoading ? ' btn--loading' : '')}>Continue to Stripe<span className="loading"></span></button>

                            </form>

                        </>

                    }

                </>

            }

        </>

    );
}

export default Billing;