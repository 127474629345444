import React, { useContext } from 'react';
// import { Link } from "react-router-dom";
import config from '../config';

// context
// import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

function Section() {

    // const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    return (
        <div className="section section--try">

            <div className="container">

                <div>

                    <h4>Try it for free</h4>

                    <p>You can start from a small gallery for free, forever. Your museum will be always available to <b>museobit.com/@<span style={{ color: config.style.callToAction }}>my-username</span></b>.</p>

                    <button onClick={(e) => { appDispatch({ type: 'setPopupRegister', data: true }) }} className="btn btn--large btn--callToAction" >Try for free</button>
                    {/* <a className="btn btn--large" href="@demo">Demo</a> */}
                    <button className="btn btn--large" onClick={(e) => { appDispatch({ type: 'setPopupDemo', data: true }) }}>Demo</button>
                    {/* <Link className="btn btn--large" to={"@demo"}>Demo</Link> */}

                </div>
                <div>

                    <div className="image-container">

                        <img src="/assets/images/section/try.png" alt="" />
                        <img src="/assets/images/section/try.jpg" alt="" />

                    </div>

                </div>

            </div>

        </div>
    );
}

export default Section;