import checkNested from './checkNested';

export default function defaultErrorMessage(e) {

    let response = false;

    if (!response) {
        response = checkNested(e, 'err', 'response', 'data', 'error', 'message');
        if (response) response = response.join(', ');
    }
    if (!response) response = checkNested(e, 'err', 'message');

    if (!response) response = JSON.stringify(e);
    if (typeof response !== 'string') response = JSON.stringify(response);

    return response;

}