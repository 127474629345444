import React, { useContext, useState, useEffect } from 'react';
import defaultErrorMessage from '../utils/defaultErrorMessage';
import checkNested from '../utils/checkNested';
import gtagE from '../utils/gtagE';
// import config from '../config';
// import miniAlert from '../utils/miniAlert';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

// components
import AdminMuseumStyle from "./AdminMuseumStyle";
import AdminMuseumAbout from "./AdminMuseumAbout";
import AdminMuseumSafety from "./AdminMuseumSafety";
import AdminMuseumGTag from "./AdminMuseumGTag";
import DotsLoading from "./DotsLoading";

// services
import serviceRefreshMuseum from "../services/refreshMuseumService";

function Admin({ user }) {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const token = appState.token;

    const [museumType, setMuseumType] = useState('Style');

    const [unsaved, setUnsaved] = useState(false);

    useEffect(() => {

        window.scrollTo(0, 0);
        gtagE('Museum');

    }, []);

    useEffect(() => {

        const request = serviceRefreshMuseum.cancelToken();
        async function fetchData() {
            setIsLoading(true);
            try {
                const response = await serviceRefreshMuseum.refresh({ token, username: user.username, request });
                if (response.data) setData(response.data);
                setTimeout(() => { setIsLoading(false); }, 500);
            } catch (e) {
                if (e.isCancel !== true) {
                    if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                    appDispatch({
                        type: "alertOpen",
                        ico: "error",
                        value: defaultErrorMessage(e)
                    });
                    setIsLoading(false);
                }
            }
        }

        if (user) fetchData();

        return () => { request.cancel(); }

    }, [token, appDispatch, user]);

    function onClickToggleWidget(type) {

        if (museumType === type) return;

        if (unsaved) {

            return appDispatch({
                type: "alertOpen",
                ico: "ok",
                value: 'Sure? Everything not saved will be lost..',
                callback: () => {
                    setMuseumType(type);
                    setUnsaved(false);
                }
            });

        }

        setMuseumType(type);

    }

    return (
        <>

            {isLoading && <DotsLoading />}

            {!isLoading && <>

                <ul style={{ maxWidth: 280 }} className="toggle-widget toggle-widget--mini">
                    <li onClick={(e) => { onClickToggleWidget('Style') }} className={museumType === 'Style' ? 'active' : null}>Style</li>
                    <li onClick={(e) => { onClickToggleWidget('About') }} className={museumType === 'About' ? 'active' : null}>About</li>
                    <li onClick={(e) => { onClickToggleWidget('Safety') }} className={museumType === 'Safety' ? 'active' : null}>Safety</li>
                    <li onClick={(e) => { onClickToggleWidget('GTag') }} className={museumType === 'GTag' ? 'active' : null}>GTag</li>
                </ul>

                {
                    museumType === 'Style' &&
                    <AdminMuseumStyle
                        user={user}
                        data={data}
                        setData={setData}
                        unsaved={unsaved}
                        setUnsaved={setUnsaved}
                    />
                }

                {
                    museumType === 'About' &&
                    <AdminMuseumAbout
                        user={user}
                        data={data}
                        setData={setData}
                        unsaved={unsaved}
                        setUnsaved={setUnsaved}
                    />
                }

                {
                    museumType === 'Safety' &&
                    <AdminMuseumSafety
                        user={user}
                        data={data}
                        setData={setData}
                        unsaved={unsaved}
                        setUnsaved={setUnsaved}
                    />
                }

                {
                    museumType === 'GTag' &&
                    <AdminMuseumGTag
                        user={user}
                        data={data}
                        setData={setData}
                        unsaved={unsaved}
                        setUnsaved={setUnsaved}
                    />
                }

            </>}

        </>
    );
}

export default Admin;
