import { useEffect, useRef } from 'react';
// import { Switch, Route } from "react-router-dom";

// components
import Page from "./Page";
// import SectionAdmin from "./SectionAdmin";
// import NotFound from "./NotFound";
import SectionMain from "./SectionMain";
import SectionPricing from "./SectionPricing";
import SectionTry from "./SectionTry";
import SectionFAQ from "./SectionFAQ";
import SectionTestimonials from "./SectionTestimonials";

const src = "https://player.vimeo.com/api/player.js";

function Home() {

    const videoRef1 = useRef(null);
    const videoRef2 = useRef(null);

    useEffect(() => {
        const script = document.createElement('script')
        script.src = src
        script.async = true
        document.body.appendChild(script)
        script.onload = function () {
            console.log('vimeo api loaded')
            const player1 = new window.Vimeo.Player(videoRef1?.current);
            const player2 = new window.Vimeo.Player(videoRef2?.current);
            setTimeout(() => {
                player1.play();
                player2.play();
            }, 0)
        }
        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <Page>
            {/* <Switch> */}
            {/* <Route path="/" exact> */}
            <SectionMain videoRef1={videoRef1} videoRef2={videoRef2} />
            {/* <SectionAdmin /> */}
            <SectionPricing />
            <SectionTry />
            <SectionFAQ />
            <SectionTestimonials />
            {/* </Route> */}
            {/* <Route path="/"><NotFound /></Route> */}
            {/* </Switch> */}
        </Page>
    );
}

export default Home;
