import React, { useContext } from 'react';
import ImageFadeIn from 'react-image-fade-in';

import StateContext from "../context/StateContext";

function Section() {

    const appState = useContext(StateContext);

    return (
        <div className="section section--bg"
        // style={{ backgroundImage: `url(/assets/images/section/hero/${appState.randomBgImage}.jpg)` }}
        >
            <ImageFadeIn className="cover" src={`/assets/images/section/hero/${appState.randomBgImage}.jpg`} />
            {/* <ImageFadeIn className="cover" loadAsBackgroundImage={true} src={`/assets/images/section/hero/${appState.randomBgImage}.jpg`} /> */}
        </div>
    );
}

export default Section;