import React, { useState, useContext } from 'react';
import config from '../config';

// context
// import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

const selectedCurrency = config.pricing.selectedCurrency;
const currencies = config.pricing.currencies;
const featuresTable = config.pricing.features;
const pricingTable = config.pricing.table;

function Section() {

    // const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const [pricing, setPricing] = useState('monthly');

    function handlePricing(type) {

        setPricing(type);

    }

    return (
        <div className="section section--pricing">

            <div className="container">

                <h3>Pricing for businesses of all sizes.</h3>
                <h4>Predictable, transparent pricing. 20% off all annual plans!</h4>

                <ul className="toggle-widget">
                    <li onClick={(e) => handlePricing('monthly')} className={pricing === 'monthly' ? "active" : null}>Monthly</li>
                    <li onClick={(e) => handlePricing('annual')} className={pricing === 'annual' ? "active" : null}>Annual</li>
                </ul>

                <div className="pricing">

                    {featuresTable.map((item, index) => (

                        <div key={index} className={'pricing__box' + (item.active ? ' selected' : '')}>

                            <h4>{item.title}</h4>

                            {pricingTable[pricing][index].price && (

                                <div className="price">

                                    <span className="currency">{currencies[selectedCurrency]}</span>
                                    <span className="value">{pricingTable[pricing][index].price[selectedCurrency]}</span>

                                </div>

                            )}

                            <h5>{item.subtitle}</h5>

                            <ul>
                                {item.list && item.list.map((el, index) => (
                                    <li key={index}>{el}</li>
                                ))}
                            </ul>

                            <button onClick={(e) => { appDispatch({ type: 'setPopupRegister', data: true }) }} className="btn btn--border btn--callToAction">{item.buttonLabel}</button>

                        </div>

                    ))}

                </div>

            </div>

        </div>
    );
}

export default Section;