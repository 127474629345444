import React, { useEffect } from 'react';
import gtagE from '../utils/gtagE';

function Page({ children }) {

    useEffect(() => {

        gtagE('Page');
        window.scrollTo(0, 0);
        // ReactGA.pageview(window.location.pathname + window.location.search);

    }, []);

    return (
        <div className="page">
            {children}
        </div>
    );
}

export default Page;
