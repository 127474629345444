import React from 'react';
import { NavLink, Switch, Route } from "react-router-dom"

// components
import AdminMuseum from "./AdminMuseum";
import AdminGallery from "./AdminGallery";
import AdminProfile from "./AdminProfile";
import AdminBilling from "./AdminBilling";
import DotsLoading from './DotsLoading';
import MaskIcon from './MaskIcon';
// import config from '../config';
// import NotFound from "./NotFound";

function Section({ userData, isLoading }) {

    const kingIcon = <MaskIcon style={{
        // backgroundColor: config.style.callToAction
        backgroundColor: '#ccc'
    }} mask={`url(/assets/images/icons/king.svg)`}></MaskIcon>

    return (

        <div className="section section--admin">

            <div className="container">

                <div className="nav">

                    <ul>
                        {/* <li><a className="active" href="#"><MaskIcon mask={`url(/assets/images/icons/cube3d.svg)`}></MaskIcon>Gallery</a></li> */}
                        <li><NavLink to={`/`} exact><MaskIcon mask={`url(/assets/images/icons/cube3d.svg)`}></MaskIcon>Gallery</NavLink></li>
                        <li><NavLink to={`/museum`} exact><MaskIcon mask={`url(/assets/images/icons/museum.svg)`}></MaskIcon>Museum</NavLink></li>
                        <li><NavLink to={`/profile`}><MaskIcon mask={`url(/assets/images/icons/user.svg)`}></MaskIcon>Profile {userData && userData.t > 1 && kingIcon}</NavLink></li>
                        {/* <li><NavLink to={`/notifications`}><MaskIcon mask={`url(/assets/images/icons/sync.svg)`}></MaskIcon>Notifications</NavLink></li> */}
                        <li><NavLink to={`/membership`}><MaskIcon mask={`url(/assets/images/icons/shield.svg)`}></MaskIcon>Membership</NavLink></li>
                        {/* <li><NavLink to={`/settings`}><MaskIcon mask={`url(/assets/images/icons/settings.svg)`}></MaskIcon>Settings</NavLink></li> */}
                    </ul>

                </div>
                <div className="content">

                    {isLoading && <DotsLoading />}

                    {!isLoading &&

                        <Switch>
                            <Route path="/profile"><AdminProfile user={userData} /></Route>
                            <Route path="/membership"><AdminBilling user={userData} /></Route>
                            <Route path="/museum"><AdminMuseum user={userData} /></Route>
                            <Route path="/"><AdminGallery user={userData} /></Route>
                            {/* <Route path="/"><NotFound /></Route> */}
                        </Switch>

                    }

                </div>

            </div>

        </div>

    );
}

export default Section;