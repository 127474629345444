import React, { useEffect, useState, useContext } from 'react';
import defaultErrorMessage from '../utils/defaultErrorMessage';
import checkNested from '../utils/checkNested';
import gtagE from '../utils/gtagE';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

// services
import serviceRefreshMembership from "../services/refreshMembershipService";

// components
import DotsLoading from "./DotsLoading";
import AdminBillingCurrent from "./AdminBillingCurrent";
import AdminBillingUpgrade from "./AdminBillingUpgrade";
import AdminBillingHistory from "./AdminBillingHistory";

function Admin({ user }) {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const token = appState.token;

    const [billingType, setBillingType] = useState('Current');

    const [membershipCurrent, setMembershipCurrent] = useState([]);
    const [membershipHistory, setMembershipHistory] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [triggerCall, setTriggerCall] = useState(0);

    useEffect(() => {

        const request = serviceRefreshMembership.cancelToken();

        async function fetchData() {
            setIsLoading(true);
            try {
                const response = await serviceRefreshMembership.refresh({ token, request });
                if (response.data.membershipCurrent) setMembershipCurrent(response.data.membershipCurrent);
                if (response.data.membershipHistory) setMembershipHistory(response.data.membershipHistory);
                setTimeout(() => { setIsLoading(false); }, 500);
            } catch (e) {
                if (e.isCancel !== true) {
                    if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                    appDispatch({
                        type: "alertOpen",
                        ico: "error",
                        value: defaultErrorMessage(e)
                    });
                    setIsLoading(false);
                }
            }
        }

        fetchData();

        return () => { request.cancel(); }

    }, [token, appDispatch, triggerCall]);

    useEffect(() => {

        window.scrollTo(0, 0);
        gtagE('Membership');

    }, []);

    return (
        <>

            {isLoading && <DotsLoading />}

            {!isLoading && <>

                <ul className="toggle-widget toggle-widget--mini" style={{ maxWidth: '280px' }}>
                    <li onClick={(e) => { setBillingType('Current') }} className={billingType === 'Current' ? 'active' : null}>Current</li>
                    <li onClick={(e) => { setBillingType('Upgrade') }} className={billingType === 'Upgrade' ? 'active' : null}>Upgrade</li>
                    <li onClick={(e) => { setBillingType('History') }} className={billingType === 'History' ? 'active' : null}>History</li>
                </ul>

                {
                    billingType === 'Current' &&
                    <AdminBillingCurrent
                        user={user}
                        membershipCurrent={membershipCurrent}
                    />
                }

                {
                    billingType === 'Upgrade' &&
                    <AdminBillingUpgrade
                        user={user}
                        membershipCurrent={membershipCurrent}
                        triggerCall={triggerCall}
                        setTriggerCall={setTriggerCall}
                        setBillingType={setBillingType}
                    />
                }

                {
                    billingType === 'History' &&
                    <AdminBillingHistory
                        membershipHistory={membershipHistory}
                    />
                }

            </>}

        </>
    );
}

export default Admin;
