import http from "./httpService";
// import qs from 'query-string'
import config from '../config';

export function cancelToken() {
    return http.CancelToken.source();
}

export async function deleteImage({ token, id, request }) {

    return new Promise(
        async (resolve, reject) => {
            try {
                const response = await http({
                    method: 'get',
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                        'authorization': token
                    },
                    url: config.backendUrl + config.endpoints.deleteImage + id,
                    cancelToken: request.token
                });
                if (response.data) return resolve(response.data);
                return reject();
            } catch (err) { return reject({ err, isCancel: http.isCancel(err) }); }
        }
    );

}

export async function upload({ token, imageBase64, imageFile, request }) {

    const formdata = new FormData();
    // form_data.append('file', imageBase64);
    formdata.append('file', imageFile);

    return new Promise(
        async (resolve, reject) => {
            try {
                const response = await http({
                    method: 'post',
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                        'authorization': token
                    },
                    data: formdata,
                    url: config.backendUrl + config.endpoints.uploadImage,
                    cancelToken: request.token
                });
                if (response.data) return resolve(response.data);
                return reject();
            } catch (err) { return reject({ err, isCancel: http.isCancel(err) }); }
        }
    );

}

export async function save({ token, gallery, galleryServerURL, galleryType, request }) {

    let formdata = new FormData();
    formdata.append('galleryType', galleryType * 1);

    if (gallery) { formdata.append('gallery', JSON.stringify(gallery)); }
    else if (galleryServerURL) { formdata.append('galleryServerURL', galleryServerURL); }

    return new Promise(
        async (resolve, reject) => {
            try {
                const response = await http({
                    method: 'post',
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                        'authorization': token
                    },
                    data: formdata,
                    url: config.backendUrl + config.endpoints.saveGallery,
                    cancelToken: request.token
                });
                if (response.data) return resolve(response.data);
                return reject();
            } catch (err) { return reject({ err, isCancel: http.isCancel(err) }); }
        }
    );
}

// eslint-disable-next-line
export default {
    cancelToken,
    upload,
    deleteImage,
    save
};
