import React, { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import ImageFadeIn from 'react-image-fade-in';
import moment from 'moment';
import checkNested from '../../utils/checkNested';
import config from '../../config';
import defaultErrorMessage from '../../utils/defaultErrorMessage';

// context
import StateContext from "../../context/StateContext";
import DispatchContext from "../../context/DispatchContext";

// services
import serviceSaveGallery from "../../services/saveGalleryService";
interface ItemProps {
    user: any,
    showMembershipMessage: any,
    listItem: any,
    index: number,
    imagesData: any,
    setImagesData: any,
    handleChange: any,
    handleUnlist: any
}

function getStyle(style: any, snapshot: any) {
    if (!snapshot.isDropAnimating) {
        return style;
    }
    return {
        ...style,
        transitionDuration: `0.001s`,
    };
}

const Item: React.FC<ItemProps> = ({ user, showMembershipMessage, listItem, index, imagesData, setImagesData, handleChange, handleUnlist }) => {

    const maxGallery = config.accountType.find((el: any) => el.id.toString() === user.t.toString())!.maxGallery;

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const token = appState.token;

    // const [isLoadingItem, setIsLoadingItem] = useState(false);

    async function handleDelete(el: any) {

        // setIsLoadingItem(true);
        const backupData = [...imagesData];
        const imagesUpdate = [...imagesData].filter((image: any) => image.id !== el.id);
        setImagesData(imagesUpdate);
        // setTimeout(() => { setIsLoadingItem(false); }, 500);

        const request = serviceSaveGallery.cancelToken();
        try {
            await serviceSaveGallery.deleteImage({ token, id: el.id, request });
        } catch (e) {
            if (e && e.isCancel !== true) {
                setImagesData(backupData);
                if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                appDispatch({
                    type: "alertOpen",
                    ico: "error",
                    value: defaultErrorMessage(e)
                });
                // setIsLoadingItem(false);
            }
        }

    }

    function confirmDelete(el: any) {

        appDispatch({
            type: "alertOpen",
            ico: "error",
            value: `Are you sure?`,
            callback: () => {
                handleDelete(el);
            }
        });

    }

    function confirmUnlist(el: any) {

        appDispatch({
            type: "alertOpen",
            ico: "error",
            value: `This is not an uploaded image, it will be lost forever. Do you want to remove it?`,
            callback: () => {
                handleUnlist(el);
            }
        });

    }

    return (
        <React.Fragment key={index}>
            {maxGallery && index === maxGallery && <span className={`hr` + (!showMembershipMessage ? ' hide' : '')}>
                <span className="message">You need to upgrade your membership</span>
                {/* <Link to={'./membership'} className="message">You need to upgrade your membership</Link> */}
            </span>}
            <Draggable key={listItem.id} draggableId={listItem.id} index={index}>
                {(provided, snapshot) => (
                    <div className={`item ${snapshot.isDragging ? "dragging" : ""} ${/*(isLoadingItem ? ' item--loading' : '')*/''} ${!listItem.image ? "opacity" : ''} ${((maxGallery && index >= maxGallery) ? ' item--opacity' : '')}`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // {...provided.dragHandleProps}
                        style={getStyle(provided.draggableProps.style, snapshot)}
                    >
                        <span {...provided.dragHandleProps} className="btn btn--drag"></span>
                        {listItem.text}

                        <div className="image">
                            <ImageFadeIn src={listItem.urlThumb ? listItem.urlThumb : listItem.url} />
                        </div>
                        <div className="inputs">
                            <textarea onChange={(e) => handleChange({ value: e.target.value, type: 'label', index })} value={listItem.label} placeholder="label"></textarea>
                            <textarea onChange={(e) => {
                                // console.log(e.target.value, listItem.description, 88);
                                handleChange({ value: e.target.value, type: 'description', index })
                            }} value={listItem.description} placeholder="description"></textarea>
                        </div>
                        <ul className="info">
                            <li>
                                <b>ID</b>
                                <span>{listItem.id}</span>
                            </li>
                            {listItem.time && <li>
                                <b>Time</b>
                                <span>{moment.unix(listItem.time).format('YYYY/MM/DD HH:mm')}</span>
                            </li>}
                        </ul>
                        <button onClick={(e) => { confirmDelete(listItem); }} type="button" className={`btn btn--remove`}><span>Remove</span></button>
                        <button onClick={(e) => { confirmUnlist(listItem); }} type="button" className={`btn btn--unlist`}><span>Remove</span></button>
                    </div>
                )}
            </Draggable>
        </React.Fragment>
    );
};

export default Item;
