import React from 'react';

function MaskIcon({ mask, children, style }) {
    return (
        <span className="icon" style={{
            ...style,
            WebkitMaskImage: mask,
            maskImage: mask
        }}>
            {children}
        </span>
    )
}

export default MaskIcon;