import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import isValidJsonString from '../utils/isValidJsonString';
import config from '../config';

// context
import DispatchContext from "../context/DispatchContext";

const maxLabel = config.maxLabel;
const maxDescription = config.maxDescription;

function Gallery({ user, demoJSON, galleryType, unsaved, setUnsaved, galleryData, setGalleryData, parentSubmit }) {

    const appDispatch = useContext(DispatchContext);

    const accountType = user.t;
    const accountTypeChecker = accountType <= 1;

    const initFocus = useRef(null);
    const [localData, setLocalData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [firstLoad, setFirstLoad] = useState(false);

    useEffect(() => {
        if (galleryData) { setLocalData(JSON.stringify(galleryData, null, 4)); }
    }, [galleryData])

    useEffect(() => {
        // if (galleryType === 'JSON' && initFocus && initFocus.current) initFocus.current.focus();
    }, [galleryType]);

    function handleSubmit(e) {

        e.preventDefault();

        if (!isValidJsonString(localData)) return appDispatch({
            type: "alertOpen",
            ico: "error",
            value: 'Oups.. JSON not valid !!!'
        });

        const parsedData = JSON.parse(localData);

        const mappedData = parsedData.map(el => {
            const url = el.url.replace(/<\/?[^>]+(>|$)/g, "").trim();

            const newLabel = el.label.length <= maxLabel ? el.label : el.label.substring(0, maxLabel);
            const newDescription = el.description.length <= maxDescription ? el.description : el.description.substring(0, maxDescription);
            return {
                url: !url.startsWith('https://') && !url.startsWith('http://') ? 'http://' + url : url,
                label: newLabel ? newLabel : '',
                description: newDescription ? newDescription : ''
            }

        });

        const filteredData = mappedData.filter(el => !el.url || el.url === 'https://' || el.url === 'http://');

        if (filteredData.length) return appDispatch({
            type: "alertOpen",
            ico: "error",
            value: 'Oups.. some URLs are missing !!!'
        });

        setFirstLoad(true);

        parentSubmit({
            gallery: mappedData,
            galleryType: config.galleryType.find(el => el.type === galleryType).id,
            setLoading: setIsLoading
        });

        setLocalData(JSON.stringify(mappedData, null, 4));

        setGalleryData(mappedData);

    }

    return (

        <>

            {accountTypeChecker && <span className="hr"><Link to={'./membership'} className="message">You need to upgrade your membership to Lite</Link></span>}

            <form onSubmit={handleSubmit} className={`form` + (accountTypeChecker ? ' form--disabled' : '')}>

                <label className="label-container">
                    <textarea
                        className="json"
                        onChange={(e) => {
                            setLocalData(e.target.value);
                            setUnsaved(true);
                        }}
                        ref={initFocus}
                        value={localData}
                    ></textarea>
                    <div className="label"><span>Your JSON input</span></div>
                </label>

                <label className="label-container disabled" disabled>
                    <pre>{demoJSON}</pre>
                    <div className="label"><span>Valid JSON input example</span></div>
                </label>

                <span className="hr"></span>

                <button disabled={!unsaved && firstLoad} type="submit" className={'btn btn--center btn--large' + (unsaved || firstLoad ? ' btn--callToAction' : '') + (isLoading ? ' btn--loading' : '')}>Save<span className="loading"></span></button>

            </form>

        </>

    );
}

export default Gallery;