import React from 'react';

function Section() {
    return (

        <div className="section section--faq">

            <div className="container">

                <h3>FAQ</h3>
                <h4>Frequently asked questions.</h4>

                <div className="faq">

                    <div>

                        <h4>Which contents could I share?</h4>
                        <p>You can share pictures, paintings, photos.. whatever you want!</p>

                    </div>

                    <div>

                        <h4>What about sensitive contents?</h4>
                        <p>We follow the netiquette rules, you will be able to mark your profile as Safe, Moderate or Restricted in every moment.</p>

                    </div>

                    <div>

                        <h4>What if I need more customization?</h4>
                        <p>You can always contact the support.</p>

                    </div>

                    <div>

                        <h4>What payment services do you support?</h4>
                        <p>We accept all major credit cards.</p>

                    </div>

                    <div>

                        <h4>Can I get a refund?</h4>
                        <p>You can cancel anytime, however we do not provide refunds.</p>

                    </div>

                    <div>

                        <h4>What if I want to change plans?</h4>
                        <p>Upgrade and downgrades are pro-rated.</p>

                    </div>

                </div>

            </div>

        </div>

    );
}

export default Section;