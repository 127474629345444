export default function tokenData(token) {

    try {
        const tokenString = token.split('.');
        const user = JSON.parse(atob(tokenString[0]));
        return user;
    } catch (e) {
        return false
    }

}