import http from "./httpService";
import qs from 'querystring'
import config from '../config';

export function cancelToken() {
    return http.CancelToken.source();
}

export async function checkEmail({ email, request }) {
    return new Promise(
        async (resolve, reject) => {
            try {
                const response = await http({
                    method: 'post',
                    url: config.backendUrl + config.endpoints.checkEmail,
                    data: qs.stringify({ email }),
                    cancelToken: request.token
                });
                if (response.data) return resolve(response.data);
                return reject();
            } catch (err) { return reject({ err, isCancel: http.isCancel(err) }); }
        }
    );
}

export async function checkUsername({ username, request }) {
    return new Promise(
        async (resolve, reject) => {
            try {
                const response = await http({
                    method: 'post',
                    url: config.backendUrl + config.endpoints.checkUsername,
                    data: qs.stringify({ username }),
                    cancelToken: request.token
                });
                if (response.data) return resolve(response.data);
                return reject();
            } catch (err) { return reject({ err, isCancel: http.isCancel(err) }); }
        }
    );
}

export async function register({ username, email, password, request }) {
    return new Promise(
        async (resolve, reject) => {
            try {
                const response = await http({
                    method: 'post',
                    // headers: { 'Access-Control-Allow-Origin': '*' },
                    // headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    url: config.backendUrl + config.endpoints.register,
                    data: qs.stringify({ username, email, password }),
                    cancelToken: request.token
                });
                if (response.data) return resolve(response.data);
                return reject();
            } catch (err) { return reject({ err, isCancel: http.isCancel(err) }); }
        }
    );
}

// eslint-disable-next-line
export default {
    cancelToken,
    register,
    checkUsername,
    checkEmail
};
