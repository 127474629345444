import React, { useContext, useState, useEffect } from 'react';
import defaultErrorMessage from '../utils/defaultErrorMessage';
import config from '../config';
import checkNested from '../utils/checkNested';
import miniAlert from '../utils/miniAlert';
import gtagE from '../utils/gtagE';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

// components
import AdminGalleryURLs from "./AdminGalleryURLs";
import AdminGalleryUpload from "./AdminGalleryUpload";
import AdminGalleryJSON from "./AdminGalleryJSON";
import AdminGalleryServer from "./AdminGalleryServer";
import DotsLoading from "./DotsLoading";

// services
import serviceRefreshGallery from "../services/refreshGalleryService";
import serviceSaveGallery from "../services/saveGalleryService";

const demoJSON = config.demoJSON;

function Admin({ user }) {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const [imagesData, setImagesData] = useState(false);
    const [galleryData, setGalleryData] = useState(false);
    const [galleryServerURL, setGalleryServerURL] = useState('');

    const [isLoading, setIsLoading] = useState(true);

    const token = appState.token;

    const [galleryType, setGalleryType] = useState('Upload');
    const [unsaved, setUnsaved] = useState(false);

    useEffect(() => {

        window.scrollTo(0, 0);
        gtagE('Gallery');

    }, []);

    function masterSubmit({ gallery, galleryType, galleryServerURL, setLoading }) {
        async function fetchData() {
            const request = serviceSaveGallery.cancelToken();
            setLoading(true);
            try {
                await serviceSaveGallery.save({ token, galleryType, galleryServerURL, gallery, request });
                setTimeout(() => { setLoading(false); }, 500);
                setUnsaved(false);
                miniAlert({
                    overflow: false,
                    autoremove: true,
                    size: 'medium',
                    text: 'Saved!'
                });
            } catch (e) {
                if (e.isCancel !== true) {
                    if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                    appDispatch({
                        type: "alertOpen",
                        ico: "error",
                        value: defaultErrorMessage(e)
                    });
                    setLoading(false);
                }
            }
        }

        if (gallery.length > 300) return appDispatch({
            type: "alertOpen",
            ico: "error",
            value: "You can't save more than 300 images"
        });

        fetchData();
    }

    useEffect(() => {

        const request = serviceRefreshGallery.cancelToken();

        async function fetchData() {
            // let mounted = true
            setIsLoading(true);
            try {
                const response = await serviceRefreshGallery.refresh({ token, request });
                // if (mounted) {
                if (response.data.gallery) setGalleryData(response.data.gallery);
                if (response.data.images) setImagesData(response.data.images);
                if (response.data.galleryServerURL) setGalleryServerURL(response.data.galleryServerURL);
                if (response.data.galleryType) {
                    const userGalleryType = response.data.galleryType ? config.galleryType.find(el => el.id.toString() === response.data.galleryType.toString()) : false;
                    if (userGalleryType) setGalleryType(userGalleryType.type)
                }
                setIsLoading(false);
                // }
            } catch (e) {
                // mounted = false;
                if (e.isCancel !== true) {
                    if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                    appDispatch({
                        type: "alertOpen",
                        ico: "error",
                        value: defaultErrorMessage(e)
                    });
                    setIsLoading(false);
                }
            }
        }

        fetchData();

        return () => { request.cancel(); }

    }, [token, appDispatch]);

    function onClickToggleWidget(type) {

        if (galleryType === type) return;

        if (unsaved) {

            return appDispatch({
                type: "alertOpen",
                ico: "ok",
                value: 'Sure? Everything not saved will be lost..',
                callback: () => {
                    setGalleryType(type);
                    setUnsaved(false);
                }
            });

        }

        setGalleryType(type);

    }

    return (
        <>

            {isLoading && <DotsLoading />}

            {!isLoading && <>

                <ul style={{ maxWidth: 280 }} className="toggle-widget toggle-widget--mini">
                    <li onClick={(e) => { onClickToggleWidget('Upload') }} className={galleryType === 'Upload' ? 'active' : null}>Upload</li>
                    <li onClick={(e) => { onClickToggleWidget('URLs') }} className={galleryType === 'URLs' ? 'active' : null}>URLs</li>
                    <li onClick={(e) => { onClickToggleWidget('JSON') }} className={galleryType === 'JSON' ? 'active' : null}>JSON</li>
                    <li onClick={(e) => { onClickToggleWidget('Server') }} className={galleryType === 'Server' ? 'active' : null}>Server</li>
                </ul>

                {
                    galleryType === 'URLs' &&
                    <AdminGalleryURLs
                        user={user}
                        galleryType={galleryType}
                        galleryData={galleryData}
                        setGalleryData={setGalleryData}
                        unsaved={unsaved}
                        setUnsaved={setUnsaved}
                        parentSubmit={masterSubmit}
                    />
                }

                {
                    galleryType === 'JSON' &&
                    <AdminGalleryJSON
                        user={user}
                        galleryType={galleryType}
                        galleryData={galleryData}
                        setGalleryData={setGalleryData}
                        unsaved={unsaved}
                        setUnsaved={setUnsaved}
                        parentSubmit={masterSubmit}
                        demoJSON={demoJSON}
                    />
                }

                {
                    galleryType === 'Server' &&
                    <AdminGalleryServer
                        user={user}
                        galleryType={galleryType}
                        galleryServerURL={galleryServerURL}
                        unsaved={unsaved}
                        setUnsaved={setUnsaved}
                        parentSubmit={masterSubmit}
                        demoJSON={demoJSON}
                    />
                }

                {
                    galleryType === 'Upload' &&
                    <AdminGalleryUpload
                        user={user}
                        galleryType={galleryType}
                        galleryData={galleryData}
                        setGalleryData={setGalleryData}
                        imagesData={imagesData}
                        setImagesData={setImagesData}
                        unsaved={unsaved}
                        setUnsaved={setUnsaved}
                        parentSubmit={masterSubmit}
                    />
                }

            </>}

        </>
    );
}

export default Admin;
