import React, { useEffect, useCallback, useContext } from 'react';
import { Redirect } from 'react-router-dom';

// context
import StateContext from "../context/StateContext";
// import DispatchContext from "../context/DispatchContext";

function Popup({ state, dispatch }) {

    const appState = useContext(StateContext);
    // const appDispatch = useContext(DispatchContext);

    const handleClose = useCallback(() => {

        dispatch({ type: 'setPopupDemo', data: false });

    }, [dispatch]);

    useEffect(() => {

        function keyPressHandler(e) {
            if (e.keyCode === 27) {
                handleClose();
            }
        }

        document.addEventListener("keyup", keyPressHandler);
        return () => { document.removeEventListener("keyup", keyPressHandler); }

    }, [handleClose]);

    if (appState.token) return <Redirect to={'/'} />

    return (

        <>

            <button onClick={(e) => handleClose()} className="btn btn--close">.</button>

            <div className="box">

                <div className="logo"></div>

                <div className="urls-widget-styler">

                    <a href="/@vangogh" className={`styler-button styler-button--2d active`}><b>Demo 2D</b></a>
                    <a href="/@fridakahlo" className={`styler-button styler-button--3d active`}>
                        <b>Demo 3D</b>
                        <div className="rosette">Alpha Test</div>
                    </a>

                </div>

            </div>

        </>

    );
}

export default Popup;
