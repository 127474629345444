import http from "./httpService";
// import qs from 'query-string'
import config from '../config';

export function cancelToken() {
    return http.CancelToken.source();
}

export async function save({ token, type, content, request }) {

    let formdata = new FormData();
    formdata.append(type, content);
    return new Promise(
        async (resolve, reject) => {
            try {
                const response = await http({
                    method: 'post',
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded',
                        'authorization': token
                    },
                    data: formdata,
                    url: config.backendUrl + config.endpoints.saveGenericString + '-' + type,
                    cancelToken: request.token
                });
                if (response.data) return resolve(response.data);
                return reject();
            } catch (err) { return reject({ err, isCancel: http.isCancel(err) }); }
        }
    );
}

// eslint-disable-next-line
export default {
    cancelToken,
    save
};
