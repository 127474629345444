import http from "./httpService";
import config from '../config';

export function cancelToken() {
    return http.CancelToken.source();
}

export async function confirmEmail({ token, request }) {
    return new Promise(
        async (resolve, reject) => {
            try {
                const response = await http({
                    url: config.backendUrl + config.endpoints.confirmEmail + token,
                    cancelToken: request.token
                });
                if (response.data) return resolve(response.data);
                return reject();
            } catch (err) { return reject({ err, isCancel: http.isCancel(err) }); }
        }
    );
}

// eslint-disable-next-line
export default {
    cancelToken,
    confirmEmail
};
