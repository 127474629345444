import React, { useState, useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import checkNested from '../../utils/checkNested';
import defaultErrorMessage from '../../utils/defaultErrorMessage';
import { base64ToBlob, arrayBufferToBase64 } from '../../utils/fileConverters';

// context
import StateContext from "../../context/StateContext";
import DispatchContext from "../../context/DispatchContext";

// services
import serviceSaveGallery from "../../services/saveGalleryService";

const okMultiple = false;
const MAX_WIDTH = 1280;
const MAX_HEIGHT = 1280;

function fitImageOntoCanvas(img, MAX_WIDTH, MAX_HEIGHT) {
    var scalingFactor = Math.min((MAX_WIDTH / img.width), (MAX_HEIGHT / img.height))

    var iw = img.width * scalingFactor;
    var ih = img.height * scalingFactor;

    var c = document.createElement('canvas');
    var ctx = c.getContext('2d');

    c.width = iw;
    c.height = ih;

    var imgData = ctx.getImageData(0, 0, iw, ih);
    var data = imgData.data;
    for (var i = 0; i < data.length; i += 4) {
        if (data[i + 3] < 255) {
            data[i] = 255;
            data[i + 1] = 255;
            data[i + 2] = 255;
            data[i + 3] = 255;
        }
    }
    ctx.putImageData(imgData, 0, 0);
    ctx.drawImage(img, 0, 0, iw, ih);
    return (c);
}

function Uploader({ imagesData, setImagesData }) {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const token = appState.token;

    const [isLoading, setIsLoading] = useState(false);
    // const [testImage, setTestImage] = useState('');

    async function uploadImage(imageBase64, imagesData) {
        // const uploadImage = useCallback(async (imageBase64) => {
        const request = serviceSaveGallery.cancelToken();

        const imageFile = base64ToBlob(imageBase64);

        setIsLoading(true);
        try {
            const response = await serviceSaveGallery.upload({ token, imageBase64, imageFile, request });
            const image = { ...response.data.image };
            const clonedImagesData = [...imagesData];
            const imagesUpdate = [...clonedImagesData, image];
            setImagesData(imagesUpdate);
            setTimeout(() => { setIsLoading(false); }, 500);
        } catch (e) {
            if (e && e.isCancel !== true) {
                if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                appDispatch({
                    type: "alertOpen",
                    ico: "error",
                    value: defaultErrorMessage(e)
                });
                setIsLoading(false);
            }
        }
    }
    // }, []);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                setIsLoading(true);
                const binaryStr = reader.result
                // let base64String = btoa(String.fromCharCode(...new Uint8Array(binaryStr)));
                const stringBase64 = arrayBufferToBase64(binaryStr);
                const imageBase64 = `data:${file.type};base64,${stringBase64}`;

                const img = new Image();
                img.crossOrigin = 'anonymous';
                img.src = imageBase64;
                img.onload = startImg;

                function startImg() {
                    var canvas = fitImageOntoCanvas(img, MAX_WIDTH, MAX_HEIGHT);
                    var imgResized = new Image();
                    // imgResized.onload=function(){ document.body.appendChild(imgResized); }
                    imgResized.src = canvas.toDataURL("image/jpeg", 0.85);
                    // console.log(imgResized.src);
                    uploadImage(imgResized.src, imagesData);
                }

                // console.log(imageBase64);

                // uploadImage(imageBase64, imagesData);
                // setTestImage(imageBase64);
            }
            reader.readAsArrayBuffer(file)
        })

        // eslint-disable-next-line
    }, [imagesData]);

    const { getRootProps, getInputProps } = useDropzone({
        // accept: "image/*",
        disabled: isLoading,
        accept: 'image/jpeg, image/png',
        // uploadMultiple: false,
        multiple: okMultiple,
        maxFiles: (okMultiple ? 0 : 1),
        onDrop
    })

    // console.log(imagesData, 'imagesData');

    return (

        <>

            <div {...getRootProps({ className: 'upload dropzone' + (isLoading ? ' loading' : '') })}>
                {/* <input type="file" multiple /> */}
                <input {...getInputProps()} />
                <div className="icon"></div>
                <div className={'btn btn--center btn--callToAction'}>Choose {okMultiple ? 'files' : 'file'} to Upload<span className="loading"></span></div>
                <div>or drag and drop {okMultiple ? 'them' : 'it'} here</div>
                <div className="loading-bar"></div>
            </div>

            {/* <div className="test" style={{ width: 100, height: 100, backgroundSize: 'contain', backgroundImage: `url(data:image/png;base64,${testImage})` }}></div> */}

        </>
    );
}

export default Uploader