import React, { useRef, useEffect, useContext, useCallback } from 'react';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

// components
function AlertReact() {

    const focusButton = useRef(null);

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const handleClose = useCallback(() => {

        appDispatch({ type: "alertClose" });

    }, [appDispatch]);

    useEffect(() => {

        function keyPressHandler(e) {
            if (e.keyCode === 27) {
                handleClose();
            }
        }

        setTimeout(() => {

            if (focusButton && focusButton.current) focusButton.current.focus();

        }, 50);

        document.addEventListener("keyup", keyPressHandler);
        return () => { document.removeEventListener("keyup", keyPressHandler); }

    }, [handleClose]);

    return (

        <div id="alert" className={"alert show--button-area--confirm" + (appState.alert.ico ? ' alert--' + appState.alert.ico : '')}>
            <div className="box">
                <div className="area">{appState.alert.text}</div>
                {
                    appState.alert.callback && (
                        <div className="button-area button-area--confirm">
                            <button onClick={() => {
                                appState.alert.callback();
                                handleClose();
                            }} className="btn btn--ok button">Ok</button>
                            <button ref={focusButton} onClick={handleClose} className="btn button">Cancel</button>
                        </div>
                    )
                }
                {
                    !appState.alert.callback && (
                        <div className="button-area button-area--ok">
                            <button ref={focusButton} onClick={handleClose} className="btn button">Ok</button>
                        </div>
                    )
                }
            </div>
        </div>

    );

}

export default AlertReact;