import React, { useContext, useEffect, useState, useMemo } from 'react'
import Column from './Uploader/Column'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import Uploader from './Uploader/Uploader';
import createGuid from '../utils/createGuid';
import config from '../config';

// context
// import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

const maxLabel = config.maxLabel;
const maxDescription = config.maxDescription;

function AdminGalleryUpload(props: any) {

  // const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  // eslint-disable-next-line
  const { user, galleryType, unsaved, setUnsaved, galleryData, setGalleryData, imagesData, setImagesData, parentSubmit } = props;

  const [localImages, setLocalImages]: any = useState([]);
  const [localGallery, setLocalGallery]: any = useState([]);

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const [saveCounter, setSaveCounter] = useState(0);
  const [saveWithDelay, setSaveWithDelay] = useState(0);

  const [showMembershipMessage, setShowMembershipMessage] = useState(true);

  // const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {

    if (galleryData && imagesData) {

      const galleryDataIDs = [...galleryData].map((el: any) => {
        const temp = { ...el };
        const tempUrlArray = temp.url.split('/');
        const tempUrlLast = tempUrlArray[tempUrlArray.length - 1].split('-');
        const id = tempUrlLast[0];
        return id;
      });

      let imagesDataCleaned: any = [];
      imagesData.forEach((image: any) => {
        const found = galleryDataIDs.find((el: any) => el === image.id);
        if (!found) imagesDataCleaned.push(image);
      });

      const guidImages: any = [...imagesDataCleaned].map(el => {
        const filename = config.assetsUrl + 'images/' + el.id + '-' + el.guid + '.' + el.ext;
        const filenameThumb = config.assetsUrl + 'images/' + el.id + '-' + el.guid + '-thumb.' + el.ext;
        let temp = {
          id: el.id,
          image: true,
          guid: el.guid,
          time: el.time,
          ext: el.ext,
          url: filename,
          urlThumb: filenameThumb,
          label: '',
          description: '',
        }
        return temp;
      });

      guidImages.reverse();
      setLocalImages(guidImages);

      // console.log(guidImages, 'guidImages');

      // }, [])

      // useEffect(() => {

      const guidGallery: any = [...galleryData].map(el => {
        const found = imagesData.find((image: any) => el.url.includes(image.id));
        let temp = { ...el }
        temp.guid = found ? found.guid : createGuid();
        temp.image = found ? true : false;
        temp.id = found ? found.id : temp.guid;
        temp.time = found ? found.time : null;
        temp.ext = found ? found.ext : null;
        return temp;
      });

      setLocalGallery(guidGallery);

      // console.log(guidGallery, 'guidGallery');

      // console.log({ guidImages, guidGallery });

    }

  }, [imagesData, galleryData])

  // const test = {
  //   description: "",
  //   label: "",
  //   url: "https://museobit.com/demo/canvas-2.jpg",
  //   ext: "jpg",
  //   guid: "3375DDBC-9380-42E2-87BB-B21B2C91FAD3",
  //   id: "6024761b01dc3c43d4003a52",
  //   time: 1613002269
  // }

  const initialColumns: any = useMemo(() => {

    return {
      inputList: {
        id: 'inputList',
        classNameStyle: 'input',
        beforeColumn:
          <Uploader
            imagesData={imagesData}
            setImagesData={setImagesData}
          // imagesData={localImages}
          // setImagesData={setLocalImages}
          />,
        list: localImages
      },
      ouputList: {
        id: 'ouputList',
        classNameStyle: 'output',
        list: localGallery
      }
    }

  }, [imagesData, localGallery, localImages, setImagesData]);

  const [columns, setColumns] = useState(initialColumns)

  useEffect(() => {

    const initialColumnsUpdated = { ...initialColumns };
    setColumns(initialColumnsUpdated);

  }, [imagesData, localImages, localGallery, initialColumns])

  useEffect(() => {

    function handleSubmitSave() {
      // function handleSubmitSave(e: any) {

      console.log('handleSubmitSave!!!!!');

      // if (e) e.preventDefault();

      // setFirstLoad(true);

      const updatedLocalData = [...localGallery].map(el => {
        const temp = { ...el }
        return {
          // url: temp.urlThumb ? temp.urlThumb : temp.url,
          url: temp.url,
          label: temp.label,
          description: temp.description
        }
      });

      // const updatedLocalImages = [...localImages].map(el => {
      //   const temp = { ...el }
      //   return {
      //     id: temp.id,
      //     guid: temp.guid,
      //     ext: temp.ext,
      //     time: temp.time
      //   }
      // });

      // console.log(localImages, 123);

      parentSubmit({
        // images: updatedLocalImages,
        gallery: updatedLocalData,
        galleryType: config.galleryType.find((el: any) => el.type === galleryType)!.id,
        setLoading: setIsLoading
      });

      setGalleryData(updatedLocalData);

      // const newColumns = { ...columns }
      // newColumns.inputList.beforeColumn = <Uploader
      //   imagesData={updatedLocalImages}
      //   setImagesData={setImagesData}
      // />;
      // newColumns.inputList.list = updatedLocalImages;
      // newColumns.ouputList.list = updatedLocalData;
      // setColumns(newColumns);

    }

    if (saveCounter) {

      handleSubmitSave();

    }

    // eslint-disable-next-line
  }, [saveCounter]);

  useEffect(() => {

    if (saveWithDelay) {

      const delay = setTimeout(() => {
        const newCounter = saveCounter + 1;
        setSaveCounter(newCounter);
      }, 2000);
      return () => {
        clearTimeout(delay);
      }

    }

    // eslint-disable-next-line
  }, [saveWithDelay]);

  function handleUnlist(el: any) {

    const filteredData = localGallery.filter((image: any) => image.url !== el.url);
    setLocalGallery(filteredData);

    const newCounter = saveCounter + 1;
    setSaveCounter(newCounter);
    // setUnsaved(true);

  }



  function handleChange(props: any) {

    const { value, type, index } = props;

    const cloneData = [...localGallery];
    // const cloneData = localGallery;
    const mappedData: any = cloneData.map((el: any, elIndex: any) => {
      // const temp = { ...el };
      const temp = el;
      if (elIndex === index) {
        let content = value;
        if (type === 'label') content = value.length <= maxLabel ? value : value.substring(0, maxLabel);
        if (type === 'description') content = value.length <= maxDescription ? value : value.substring(0, maxDescription);
        temp[type] = content;
      }
      return temp;
    })
    setLocalGallery(mappedData);
    // setUnsaved(true);

    const newCounter = saveWithDelay + 1;
    setSaveWithDelay(newCounter);

  }

  function onDragStart() {

    setShowMembershipMessage(false);

  }

  const onDragEnd = ({ source, destination }: DropResult) => {

    setShowMembershipMessage(true);

    if (destination === undefined || destination === null) return null

    if (
      source.droppableId === destination.droppableId &&
      destination.index === source.index
    )
      return null

    const start = columns[source.droppableId]
    const end = columns[destination.droppableId]

    if (start === end) {

      const newList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      )

      newList.splice(destination.index, 0, start.list[source.index])

      const newCol = {
        id: start.id,
        list: newList,
        beforeColumn: start.beforeColumn,
        classNameStyle: start.classNameStyle
      }

      setColumns((state: any) => ({ ...state, [newCol.id]: newCol }))

      if (newCol.classNameStyle === 'output') {

        setLocalGallery(newCol.list);

        const newCounter = saveCounter + 1;
        setSaveCounter(newCounter);
        // setUnsaved(true);

      }

      return null

    } else {

      const newStartList = [...start.list].filter(
        // const newStartList = start.list.filter(
        (_: any, idx: number) => idx !== source.index
      )

      const newStartCol = {
        id: start.id,
        list: newStartList,
        beforeColumn: start.beforeColumn,
        classNameStyle: start.classNameStyle
      }

      const newEndList = [...end.list];
      // const newEndList = end.list;

      newEndList.splice(destination.index, 0, [...start.list][source.index])
      // newEndList.splice(destination.index, 0, start.list[source.index])

      const newEndCol = {
        id: end.id,
        list: newEndList,
        beforeColumn: end.beforeColumn,
        classNameStyle: end.classNameStyle
      }

      if (
        end.classNameStyle === 'input' &&
        newEndCol.list.find((el: any) => el.image === false)
      ) {
        return appDispatch({
          type: "alertOpen",
          ico: "error",
          value: 'This is not an uploaded image'
        });
      }

      setColumns((state: any) => ({
        ...state,
        [newStartCol.id]: newStartCol,
        [newEndCol.id]: newEndCol
      }))

      if (newStartCol.classNameStyle === "output") {
        setLocalGallery(newStartCol.list);
        setLocalImages(newEndCol.list);
      } else if (newEndCol.classNameStyle === "output") {
        setLocalGallery(newEndCol.list);
        setLocalImages(newStartCol.list);
      }

      const newCounter = saveCounter + 1;
      setSaveCounter(newCounter);
      // setUnsaved(true);

      return null
    }
  }

  return (
    // <form onSubmit={handleSubmitSave} className="form">
    <form className="form">

      {/* <DragDropContext onDragEnd={() => { }}> */}
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <div className="urls-widget-uploader">
          {Object.values(columns).map((col: any, index) => (
            <Column
              col={col}
              key={index}
              user={user}
              showMembershipMessage={showMembershipMessage}
              imagesData={imagesData}
              setImagesData={setImagesData}
              handleChange={handleChange}
              handleUnlist={handleUnlist}
            />
          ))}
        </div>
      </DragDropContext>

      <span className="hr"></span>

      {/* <button disabled={!unsaved && firstLoad} type="submit" className={'btn btn--center btn--large' + (unsaved || firstLoad ? ' btn--callToAction' : '') + (isLoading ? ' btn--loading' : '')}>Save<span className="loading"></span></button> */}

    </form>
  )
}

export default AdminGalleryUpload
