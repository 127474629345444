let miniAlertClicked = false;

function getRandomInt(min, max) {

    return Math.floor(Math.random() * (max - min + 1) + min);

}

// function actionCloseMiniAlert(obj) {

//     const dom = document.querySelector('.miniAlert');
//     const domBg = document.querySelector('.miniAlertBg');

//     dom.classList.remove('show');

//     setTimeout(function () {
//         dom.parentNode.removeChild(dom);
//         if (domBg != undefined) domBg.parentNode.removeChild(domBg);
//         miniAlertClicked = false;
//     }, 500);

// }

function miniAlert(obj) {

    if (!miniAlertClicked) {

        miniAlertClicked = true;

        const dom = document.createElement('div');
        dom.classList.add('miniAlert');
        dom.classList.add('miniAlert--' + obj.size);
        dom.innerHTML = obj.text;
        document.querySelector('body').appendChild(dom);

        let domBg = false;

        if (obj.overflow) {

            domBg = document.createElement('div');
            domBg.classList.add('miniAlertBg');
            domBg.classList.add('action');
            domBg.classList.add('action--close-miniAlert');
            document.querySelector('body').appendChild(domBg);

        }

        setTimeout(function () {
            dom.classList.add('show');
            dom.classList.add('show--' + getRandomInt(1, 8));
        }, 50);

        if (obj.autoremove) {

            setTimeout(function () {
                dom.classList.remove('show');
            }, 500);
            setTimeout(function () {
                dom.parentNode.removeChild(dom);
                if (domBg) domBg.parentNode.removeChild(domBg);
            }, 1000);

            setTimeout(function () {
                miniAlertClicked = false;
            }, 300);

        }

        // else {

        //     dom.classList.add('action');
        //     dom.classList.add('action--close-miniAlert');

        // }

    }

}

export default miniAlert;