import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import config from '../config';
import createGuid from '../utils/createGuid';

// context
import DispatchContext from "../context/DispatchContext";

const maxLabel = config.maxLabel;
const maxDescription = config.maxDescription;

function Gallery({ user, galleryType, unsaved, setUnsaved, galleryData, setGalleryData, parentSubmit }) {

    const history = useHistory();

    const appDispatch = useContext(DispatchContext);

    const accountType = user.t;
    const accountTypeChecker = accountType <= 1;

    const maxGallery = config.accountType.find(el => el.id.toString() === user.t.toString()).maxGallery;

    const initFocus = useRef(null);
    const [localData, setLocalData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [URL, setURL] = useState('');
    const [label, setLabel] = useState('');
    const [description, setDescription] = useState('');

    const [showMembershipMessage, setShowMembershipMessage] = useState(true);

    const [firstLoad, setFirstLoad] = useState(false);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    useEffect(() => {
        if (galleryData) {
            const mappedData = [...galleryData].map(el => {
                const temp = { ...el };
                temp.mockId = createGuid();
                return temp;
            });
            setLocalData(mappedData);
        }
    }, [galleryData])

    useEffect(() => {
        // if (galleryType === 'URLs' && initFocus && initFocus.current) initFocus.current.focus();
    }, [galleryType]);

    function handleSubmitSave(e) {

        e.preventDefault();

        setFirstLoad(true);

        const updatedLocalData = [...localData].map(el => {
            const temp = { ...el }
            return {
                url: temp.url,
                label: temp.label,
                description: temp.description
            }
        })

        parentSubmit({
            gallery: updatedLocalData,
            galleryType: config.galleryType.find(el => el.type === galleryType).id,
            setLoading: setIsLoading
        });

        setGalleryData(updatedLocalData);

    }

    function handleRemove(e, index) {

        e.preventDefault();

        const find = localData.find((el, elIndex) => elIndex === index);
        const urlMini = find.url.length > 25 ? find.url.substring(0, 22) + '...' : find.url;

        appDispatch({
            type: "alertOpen",
            ico: "error",
            value: `Do you want to remove ${urlMini}?`,
            callback: () => {

                const filteredData = localData.filter((el, elIndex) => elIndex !== index);
                setLocalData(filteredData);
                setUnsaved(true);

            }
        });

    }

    function handleSubmitAdd(e) {

        e.preventDefault();

        let cleanURL = URL.replace(/<\/?[^>]+(>|$)/g, "").trim();
        cleanURL = !cleanURL.startsWith('https://') && !cleanURL.startsWith('http://') ? 'http://' + cleanURL : cleanURL;

        if (!cleanURL.length || cleanURL === 'https://' || cleanURL === 'http://') return appDispatch({
            type: "alertOpen",
            ico: "error",
            value: 'Please enter a valid URL'
        });

        const cleanLabel = label ? label.replace(/<\/?[^>]+(>|$)/g, "").trim() : '';
        const cleanDescription = description ? description.replace(/<\/?[^>]+(>|$)/g, "").trim() : '';

        const newObj = {
            mockId: createGuid(),
            url: cleanURL,
            label: cleanLabel,
            description: cleanDescription
        }
        const clonedData = [newObj, ...localData];
        setLocalData(clonedData);
        setURL('');
        setLabel('');
        setDescription('');
        setUnsaved(true);

    }

    function handleChange({ value, type, index }) {
        const cloneData = [...localData];
        const mappedData = cloneData.map((el, elIndex) => {
            const temp = { ...el };
            if (elIndex === index) {
                let content = value;
                if (type === 'label') content = value.length <= maxLabel ? value : value.substring(0, maxLabel);
                if (type === 'description') content = value.length <= maxDescription ? value : value.substring(0, maxDescription);
                temp[type] = content;
            }
            return temp;
        })
        setLocalData(mappedData);
        setUnsaved(true);
    }

    function onDragStart() {

        setShowMembershipMessage(false);

    }

    function onDragEnd(result) {

        if (!result.destination) {
            return;
        }

        const newItems = reorder(
            localData,
            result.source.index,
            result.destination.index
        );

        setLocalData(newItems);
        setShowMembershipMessage(true);
        setUnsaved(true);

    }

    function handleMembershipMessage() {

        if (unsaved) {

            return appDispatch({
                type: "alertOpen",
                ico: "ok",
                value: 'Sure? Everything not saved will be lost..',
                callback: () => {
                    history.push('/membership');
                }
            });

        }

        return history.push('/membership');

    }

    return (

        <>

            {accountTypeChecker && <span className="hr"><Link to={'./membership'} className="message">You need to upgrade your membership to Lite</Link></span>}

            <form onSubmit={handleSubmitAdd} className={`form` + (accountTypeChecker ? ' form--disabled' : '')}>

                <div className="urls-widget-container urls-widget-container--main">
                    <div className="urls-widget">
                        <div>
                            <label className="label-container">
                                <input
                                    type="input"
                                    ref={initFocus}
                                    value={URL}
                                    onChange={(e) => {
                                        setURL(e.target.value);
                                        setUnsaved(true);
                                    }}
                                />
                                <div className="label"><span>URL</span></div>
                            </label>

                            <label className="label-container">
                                <textarea
                                    // type="input"
                                    value={label}
                                    placeholder={
                                        `#This is a title
*This text is italic*
**This text is bold**`
                                    }
                                    style={{
                                        height: 73
                                    }}
                                    onChange={(e) => {
                                        const content = e.target.value.length <= maxLabel ? e.target.value : e.target.value.substring(0, maxLabel);
                                        setLabel(content);
                                        setUnsaved(true);
                                    }}
                                />
                                <div className="label"><span>Label <small>( optional )</small> <b style={{ float: 'right' }}>{label.length}<small> / {maxLabel}</small></b></span></div>
                            </label>

                            <label className="label-container">
                                <textarea
                                    // type="input"
                                    value={description}
                                    placeholder={
                                        `#This is a title
*This text is italic*
**This text is bold**`
                                    }
                                    style={{
                                        height: 73
                                    }}
                                    // placeholder={`(optional)`}
                                    onChange={(e) => {
                                        const content = e.target.value.length <= maxDescription ? e.target.value : e.target.value.substring(0, maxDescription);
                                        setDescription(content);
                                        setUnsaved(true);
                                    }}
                                />
                                <div className="label"><span>Description <small>( optional )</small> <b style={{ float: 'right' }}>{description.length}<small> / {maxDescription}</small></b></span></div>
                            </label>
                        </div>
                        <button type="submit" className='btn btn--callToAction'><span>Add</span></button>
                    </div>
                </div>

            </form>

            <form onSubmit={handleSubmitSave} className="form">

                {localData && localData.length > 0 &&

                    <>

                        <span className="hr"></span>

                        <div className="urls-widget-container">

                            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {localData.map((item, index) => (
                                                <React.Fragment key={index}>
                                                    {index === maxGallery && <span className={`hr` + (!showMembershipMessage ? ' hide' : '')}>
                                                        <button onClick={handleMembershipMessage} className="message">You need to upgrade your membership</button>
                                                        {/* <Link to={'./membership'} className="message">You need to upgrade your membership</Link> */}
                                                    </span>}
                                                    <Draggable key={item.mockId} draggableId={item.mockId} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                className={`urls-widget` + ((index >= maxGallery) ? ' urls-widget--opacity' : '')}
                                                            >
                                                                <span
                                                                    {...provided.dragHandleProps}
                                                                    className='btn btn--drag'
                                                                ></span>
                                                                <div>
                                                                    <input type="text" onChange={(e) => handleChange({ value: e.target.value, type: 'url', index })} placeholder="url" value={item.url} />
                                                                    <textarea
                                                                        // type="text"
                                                                        onChange={(e) => handleChange({ value: e.target.value, type: 'label', index })} placeholder="label" value={item.label} />
                                                                    <textarea
                                                                        // type="text"
                                                                        onChange={(e) => handleChange({ value: e.target.value, type: 'description', index })} placeholder="description" value={item.description} />
                                                                </div>
                                                                <button type="button" onClick={(e) => handleRemove(e, index)} className='btn'><span>Remove</span></button>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </React.Fragment>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div >

                    </>

                }

                <span className="hr"></span>

                <button disabled={!unsaved && firstLoad} type="submit" className={'btn btn--center btn--large' + (unsaved || firstLoad ? ' btn--callToAction' : '') + (isLoading ? ' btn--loading' : '')}>Save<span className="loading"></span></button>

            </form >

        </>
    );
}

export default Gallery;