import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import config from '../config';

// context
import DispatchContext from "../context/DispatchContext";

function Gallery({ user, demoJSON, galleryType, unsaved, setUnsaved, galleryServerURL, parentSubmit }) {

    const appDispatch = useContext(DispatchContext);

    const accountType = user.t;
    const accountTypeChecker = accountType <= 2;

    const initFocus = useRef(null);
    const [localData, setLocalData] = useState(galleryServerURL || '');
    const [isLoading, setIsLoading] = useState(false);

    const [firstLoad, setFirstLoad] = useState(false);

    useEffect(() => {
        // if (!accountTypeChecker && galleryType === 'Server' && initFocus && initFocus.current) initFocus.current.focus();
    }, [galleryType, accountTypeChecker]);

    function handleSubmit(e) {

        e.preventDefault();

        if (
            (!localData.length) ||
            (!localData.startsWith('https://') && !localData.startsWith('http://')) ||
            (localData === 'https://') ||
            (localData === 'http://')
        ) return appDispatch({
            type: "alertOpen",
            ico: "error",
            value: 'Oups.. invalid URL !!!'
        });

        setFirstLoad(true);

        parentSubmit({
            galleryServerURL: localData,
            galleryType: config.galleryType.find(el => el.type === galleryType).id,
            setLoading: setIsLoading
        });

    }

    return (

        <>

            {accountTypeChecker && <span className="hr"><Link to={'./membership'} className="message">You need to upgrade your membership to Professional</Link></span>}

            <form onSubmit={handleSubmit} className={`form` + (accountTypeChecker ? ' form--disabled' : '')}>

                <label className="label-container">
                    <input
                        placeholder="https://your-server-URL-with-valid-json-output"
                        className="json"
                        onChange={(e) => {
                            setLocalData(e.target.value);
                            setUnsaved(true);
                        }}
                        ref={initFocus}
                        value={localData}
                    ></input>
                    <div className="label"><span>Your JSON server</span></div>
                </label>

                <label className="label-container disabled" disabled>
                    <pre>{demoJSON}</pre>
                    <div className="label"><span>Valid JSON server output example</span></div>
                </label>

                <span className="hr"></span>

                <button disabled={!unsaved && firstLoad} type="submit" className={'btn btn--center btn--large' + (unsaved || firstLoad ? ' btn--callToAction' : '') + (isLoading ? ' btn--loading' : '')}>Save<span className="loading"></span></button>

            </form>

        </>
    );
}

export default Gallery;