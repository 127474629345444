import React from 'react';
import { Link } from "react-router-dom";
import tokenData from '../utils/tokenData';
// import Iubenda from 'react-iubenda-policy'

function Footer({ isLogged, state, dispatch, handleLogout }) {

    const user = state.token ? tokenData(state.token) : false;
    const username = user && user.username ? user.username : 'demo';

    return (

        <>

            <div data-nosnippet className="footer">

                <div className="container">

                    <div>
                        <div className="logo"></div>
                        <p>
                            © Copyright {(new Date()).getFullYear()}<br />
                            {/* <small>orsi.me LTD, Company Reg No. 12621541, VAT No. 350145436, Registered Address: First Floor, Telecom House, 125-135 Preston Road, Brighton, East Sussex, United Kingdom, BN1 6AF.</small> */}
                        </p>
                    </div>

                    <ul>

                        {!isLogged && (
                            <>
                                {/* <li><Link className="highlight" to={"@demo"}>Demo</Link></li> */}
                                {/* <li><a href="@demo" className="highlight">Demo</a></li> */}
                                <li><button className="highlight" onClick={(e) => { dispatch({ type: 'setPopupDemo', data: true }) }}>Demo</button></li>
                                <li><button onClick={(e) => { dispatch({ type: 'setPopupLogin', data: true }) }}>Log In</button></li>
                                <li><button onClick={(e) => { dispatch({ type: 'setPopupRegister', data: true }) }}>Sign Up</button></li>
                            </>
                        )}

                        {isLogged && (
                            <>
                                <li><Link className="highlight" to={'./'}>Dashboard</Link></li>
                                <li><a href={"/@" + username}>3D Gallery</a></li>
                            </>
                        )}

                    </ul>

                    <ul>

                        {!isLogged && (
                            <>
                                <li><a className="messenger" target="_blank" rel="noreferrer" href={"https://m.me/museobit"}>Support</a></li>
                                <li><Link to={"privacy-policy"}>Privacy Policy</Link></li>
                                <li><Link to={"cookie-policy"}>Cookie Policy</Link></li>
                                <li><Link to={"terms-of-use"}>Terms of Use</Link></li>

                                {/* <li><Iubenda id={38701094} type='privacy' styling='nostyle'>Privacy Policy</Iubenda></li>
                                <li><Iubenda id={38701094} type='cookie' styling='nostyle'>Cookie Policy</Iubenda></li> */}

                            </>
                        )}

                        {isLogged && (
                            <>
                                <li><a className="messenger" target="_blank" rel="noreferrer" href={"https://m.me/museobit"}>Support</a></li>
                                <li><button onClick={handleLogout}>Logout</button></li>
                            </>
                        )}

                    </ul>

                    <div>
                        <span>museobit.</span><br />
                        3rd Floor 86 - 90 Paul Street<br />
                        London<br />
                        England<br />
                        EC2A 4NE<br />
                        United Kingdom
                    </div>

                </div>

            </div>

        </>

    );
}

export default Footer;
