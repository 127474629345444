import defaultErrorMessage from './defaultErrorMessage';

import service from '../services/confirmEmailService';

export default function confirmEmailInit({ token, dispatch }) {

    async function fetchData() {
        const request = service.cancelToken();
        try {
            const response = await service.confirmEmail({ token, request });
            if (response.data.confirmed) {
                dispatch({
                    type: "alertOpen",
                    ico: "mail",
                    value: "Email confirmed"
                });
                // dispatch({
                //     type: 'setPopupLogin',
                //     data: true
                // });
            } else {
                dispatch({
                    type: "alertOpen",
                    ico: "mail",
                    value: "Something wrong with your email confirmation.."
                });
            }
        } catch (e) {
            if (e.isCancel !== true) {
                dispatch({
                    type: "alertOpen",
                    ico: "error",
                    value: defaultErrorMessage(e)
                });
            }
        }
    }

    fetchData();

}