import React, { useState, useContext } from 'react';
// import { encode } from 'html-entities';
import defaultErrorMessage from '../utils/defaultErrorMessage';
import checkNested from '../utils/checkNested';
import miniAlert from '../utils/miniAlert';
import Clipboard from 'react-clipboard.js';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

// services
import serviceSaveGenericObj from "../services/saveGenericObjService";

// const iframeStyle = {
//     overflowWrap: 'break-word',
//     wordWrap: 'break-word',
//     wordBreak: 'break-all',
//     fontFamily: 'monospace',
//     // wordBreak: 'break-word',
//     hyphens: 'auto',
//     fontSize: '13px',
//     color: '#999'
// }

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

function Museum({ user, data, setData, unsaved, setUnsaved }) {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const [isLoading, setIsLoading] = useState(false);

    const [localData, setLocalData] = useState(data.style || {});
    const [firstLoad, setFirstLoad] = useState(false);

    const [styleType, setStyleType] = useState(localData.type || '2d');

    const [domain, setDomain] = useState('');
    const [domains, setDomains] = useState([]);

    const token = appState.token;

    function onClickToggleStyle(type) {

        if (styleType === type) return;
        setUnsaved(true);
        setStyleType(type);

        let updateLocalData = { ...localData };
        updateLocalData.type = type;
        setLocalData(updateLocalData);

    }

    function removeDomain(el) {

        return appDispatch({
            type: "alertOpen",
            ico: "ok",
            value: `Do you want to remove ${el}?`,
            callback: () => {
                const filteredDomains = domains.filter(dm => dm !== el);
                setDomains(filteredDomains);
                setUnsaved(false);
            }
        });

    }

    function handleSubmitDomain(e) {

        e.preventDefault();

        if (domain.length) {

            let cleanURL = domain.trim().toLowerCase();

            if (
                (!cleanURL.startsWith('https://') && !cleanURL.startsWith('http://')) ||
                !validURL(cleanURL)
            ) return appDispatch({
                type: "alertOpen",
                ico: "error",
                value: 'Please enter a valid URL'
            });

            setDomains([...domains, cleanURL]);
            setDomain('');

        }

    }

    function handleSubmitStyle(e) {

        e.preventDefault();

        async function fetchData() {
            const request = serviceSaveGenericObj.cancelToken();
            setIsLoading(true);
            try {
                await serviceSaveGenericObj.save({ token, content: localData, type: 'style', request });
                setTimeout(() => { setIsLoading(false); }, 500);
                setUnsaved(false);

                const updateData = { ...data, style: localData };
                setData(updateData);

                setFirstLoad(true);
                miniAlert({
                    overflow: false,
                    autoremove: true,
                    size: 'medium',
                    text: 'Saved!'
                });
            } catch (e) {
                if (e.isCancel !== true) {
                    if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                    appDispatch({
                        type: "alertOpen",
                        ico: "error",
                        value: defaultErrorMessage(e)
                    });
                    setIsLoading(false);
                }
            }
        }
        fetchData();

    }

    function handleFormCopy() {

        miniAlert({
            overflow: false,
            autoremove: true,
            size: 'small',
            text: 'Code Copied!'
        });

    }

    return (

        <>

            <form onSubmit={handleSubmitStyle} className="form">

                <div className="urls-widget-styler">

                    <div onClick={(e) => { onClickToggleStyle('2d') }} className={`styler-button styler-button--2d` + (styleType === '2d' ? ' active' : '')}></div>
                    <div onClick={(e) => { onClickToggleStyle('3d') }} className={`styler-button styler-button--3d` + (styleType === '3d' ? ' active' : '')}>
                        <div className="rosette">Alpha Test</div>
                    </div>

                </div>

                {
                    styleType === '2d' && <></>
                }

                {
                    styleType === '3d' && <></>
                }

                <span className="hr"></span>

                <button disabled={!unsaved && firstLoad} type="submit" className={'btn btn--center btn--large' + (unsaved || firstLoad ? ' btn--callToAction' : '') + (isLoading ? ' btn--loading' : '')}>Save<span className="loading"></span></button>

            </form>

            {/* <span className="hr"></span>
            <form onSubmit={handleSubmitDomain} className="form"> */}

            <span className="hr"><span className="message">Coming soon</span></span>
            <form onSubmit={handleSubmitDomain} className="form form--disabled">

                <div className="urls-widget-container urls-widget-container--main urls-widget-container--main--tags">
                    <div className="urls-widget">
                        <div>
                            <label className="label-container">
                                <input
                                    placeholder="http://domain"
                                    type="input"
                                    value={domain}
                                    onChange={(e) => {
                                        setDomain(e.target.value);
                                        setUnsaved(true);
                                    }}
                                />
                                <div className="label"><span>URL</span></div>
                            </label>
                        </div>
                        <button type="submit" className='btn btn--callToAction'><span>Add</span></button>
                    </div>
                    <div className="urls-widget urls-widget--tags">
                        {domains.map((el, index) => <div onClick={(e) => removeDomain(el)}>{el}</div>)}
                    </div>
                </div>

                <label onClick={handleFormCopy} className="label-container label-container--code disabled" disabled>

                    <Clipboard data-clipboard-text={`<iframe style="background: #eee; width: 100%; height: 100%; border: 0;" src="https://museobit.com/@${user.username}"></iframe>`}></Clipboard>

                    <div className="code">{(`<iframe style="background: #eee; width: 100%; height: 100%; border: 0;" src="https://museobit.com/@${user.username}"></iframe>`)}</div>
                    {/* <pre style={iframeStyle}>{(`<iframe style="background: #eee; width: 100%; height: 100%; border: 0;" src="https://orsi.me/test.php"></iframe>`)}</pre> */}
                    {/* <pre>{encode(`<iframe style="background: #eee; width: 100%; height: 100%; border: 0;" src="https://orsi.me/test.php"></iframe>`)}</pre> */}
                    <div className="label"><span>Paste this code in your HTML</span></div>
                </label>

                <span className="hr"></span>

                <button disabled={!unsaved && firstLoad} type="submit" className={'btn btn--center btn--large' + (unsaved || firstLoad ? ' btn--callToAction' : '') + (isLoading ? ' btn--loading' : '')}>Save<span className="loading"></span></button>

            </form>

        </>

    );
}

export default Museum;