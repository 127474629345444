import React, { useContext } from 'react';
import { Link } from "react-router-dom";

// import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

const Cookie = React.forwardRef((props, ref) => {

    // const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    function handleClose() {

        appDispatch({ type: "setCookie" });

    }

    return (

        <div ref={ref} className="cookie">

            <h4>But first, cookies</h4>
            <p>We use cookies to improve your experience and deliver personalized content. By using Museobit, you agree to our <Link onClick={() => appDispatch({ type: "popupGenericOpen" })} to="/cookie-policy">Cookie Policy</Link>.</p>

            <div className="btn" onClick={handleClose}>Got it</div>

        </div>

    );
});

export default Cookie;
