const tag = 'G-KSEW29Z13Q';
let counter = false;

function gtagE(title) {

    if (window.location.hostname !== 'localhost') {

        if (!counter) {

            let tagString = `<script async src="https://www.googletagmanager.com/gtag/js?id=${tag}"></script>`;
            tagString += `
<script>
window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());
</script>
`;

            var range = document.createRange();
            range.selectNode(document.getElementsByTagName("head")[0]);
            var documentFragment = range.createContextualFragment(tagString);
            document.body.appendChild(documentFragment);

            counter = true;

        }

        return window.gtag('config', tag, {
            'page_title': title,
            'page_path': window.location.pathname
        });

    }

}

export default gtagE;