import React from "react";
import Item from "./Item";
import { Droppable } from "react-beautiful-dnd";

interface ColumnProps {
    col: {
        id: string,
        beforeColumn: any,
        classNameStyle: string,
        list: string[]
    },
    showMembershipMessage: any,
    user: any,
    imagesData: any,
    setImagesData: any,
    handleChange: any,
    handleUnlist: any
}

const Column: React.FC<ColumnProps> = ({ user, showMembershipMessage, imagesData, setImagesData, handleChange, handleUnlist, col: { list, id, beforeColumn, classNameStyle } }) => {
    return (
        <Droppable droppableId={id}>
            {(provided) => (

                <div className={`urls-widget-container urls-widget-container--sticky urls-widget-container--main ${classNameStyle}`}>
                    {beforeColumn}
                    <div className="list-area" {...provided.droppableProps} ref={provided.innerRef}>

                        {
                            classNameStyle === 'output' &&
                            list.length === 0 &&
                            <div className="face-box">
                                <div className="face face--sad"></div>
                                <h3>Your gallery is empty</h3>
                            </div>
                        }

                        {list.map((listItem, index) => (
                            <Item
                                user={user}
                                showMembershipMessage={showMembershipMessage}
                                key={index}
                                listItem={listItem}
                                index={index}
                                imagesData={imagesData}
                                setImagesData={setImagesData}
                                handleChange={handleChange}
                                handleUnlist={handleUnlist}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                </div>

            )}
        </Droppable>
    );
};

export default Column;
