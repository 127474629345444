let config = {
    maxLabel: 128,
    maxDescription: 1000,
    style: {
        mainColor: '#66cc99',
        callToAction: '#3366cc'
    },
    supportBase64value: atob('c3VwcG9ydEBtdXNlb2JpdC5jb20='), // support@museobit.com
    backendUrl: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BACKENDURL : process.env.REACT_APP_BACKENDURLDEBUG,
    assetsUrl: process.env.REACT_APP_ASSETSURL,
    // stripe: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,

    // museobit.com
    stripe: 'pk_live_51IEzElIfTSKbQ5xt8znw9F0eviDgN40lXhzhfxoUfvNFiu5IhMlZlHbI4Aj7rfK0JD1NSRvNb7fgJvbv6NRyMSv100OAQvyXnQ',
    // stripe: 'pk_test_51IEzElIfTSKbQ5xtAsEg866bYjl8lzanhb3MyL6OtoK57lqBU5E1mGc2gXke3kWYUgaGhJAx4K7j83A9iMAp5mzO003ALisL44',

    // orsi.me
    // stripe: 'pk_live_51HyGtbBi2W4AQQpBlB3yDKelc5eKrj71jjRc5apktSBIyUbr2Ur8a71n1NowmoC09lQFiSoxv5wTRrMWDBrxO6Xu00T4p5EB2Q',
    // stripe: 'pk_test_51HyGtbBi2W4AQQpBtsaEU3ol0KCwGCYZUnVhpaXUgOQM3U9hqLybjY31jzBVStgIfN2Do6IBcXnFF71DldyeMTQl00HAc11z45',

    endpoints: {
        // facebookLogin: 'login/facebook',
        confirmEmail: '/confirm-email/',
        couponCheckCode: '/coupon-check-code',
        couponUpgradeMembership: '/coupon-upgrade-membership',
        checkUsername: '/check-username',
        checkEmail: '/check-email',
        changePassword: '/change-password',
        createCheckoutSession: '/create-checkout-session',
        deleteImage: '/delete-image/',
        login: '/login',
        register: '/register',
        refreshGallery: '/refresh-gallery',
        refreshMembership: '/refresh-membership',
        refreshMuseum: '/user/@',
        refreshUser: '/refresh-user',
        saveGallery: '/save-gallery',
        saveGenericString: '/save-string',
        saveGenericObj: '/save-obj',
        unsubscribe: '/unsubscribe',
        uploadImage: '/upload-image'
    },
    galleryType: [
        { id: 1, type: 'URLs' },
        { id: 2, type: 'JSON' },
        { id: 3, type: 'Server' },
        { id: 4, type: 'Upload' }
    ],
    accountType: [
        { id: 1, label: 'Free', maxGallery: 5 },
        { id: 2, label: 'Lite', maxGallery: 50 },
        { id: 3, label: 'Professional', maxGallery: 300 }
    ],
    pricing: {
        selectedCurrency: 'usd',
        currencies: {
            usd: '$',
            gbp: '£',
            eur: '€'
        },
        features: [
            {
                id: 1,
                title: 'Free',
                list: [
                    '5 images'
                ],
                buttonLabel: 'Free'
            },
            {
                id: 2,
                title: 'Lite',
                list: [
                    '50 images',
                    'No banners'
                ],
                buttonLabel: 'Start with Lite'
            },
            {
                id: 3,
                active: true,
                title: 'Professional',
                list: [
                    '300 images',
                    'No banners',
                    'No watermarks',
                    // 'CNAME'
                ],
                buttonLabel: 'Start with Professional'
            },
            {
                id: 4,
                title: 'Custom',
                list: [
                    'Unlimited images',
                    'No banners',
                    'No watermarks',
                    // 'CNAME',
                    'Customizable solution',
                    'Domain'
                ],
                buttonLabel: 'Contact Sales'
            }
        ],
        table: {
            monthly: [
                {
                    id: 1,
                    label: 'Free',
                    subtitle: 'per month',
                    price: { usd: 0, gbp: 0, eur: 0 }
                },
                {
                    id: 2,
                    label: 'Lite',
                    subtitle: 'per month',
                    price: { usd: 1.50, gbp: 1.50, eur: 1.50 }
                },
                {
                    id: 3,
                    label: 'Professional',
                    subtitle: 'per month',
                    price: { usd: 9.90, gbp: 9.90, eur: 9.90 }
                },
                {
                    id: 4,
                    label: 'Custom',
                    subtitle: 'Custom plan'
                }
            ],
            annual: [
                {
                    id: 1,
                    title: 'Free',
                    subtitle: 'per year',
                    price: { usd: 0, gbp: 0, eur: 0 }
                },
                {
                    id: 2,
                    title: 'Lite',
                    subtitle: 'per year',
                    price: { usd: 14.90, gbp: 14.90, eur: 14.90 }
                },
                {
                    id: 3,
                    title: 'Professional',
                    subtitle: 'per year',
                    price: { usd: 94.90, gbp: 94.90, eur: 94.90 }
                },
                {
                    id: 4,
                    title: 'Custom',
                    subtitle: 'Custom plan'
                }
            ]
        }
    },
    demoJSON: `[
    {
        "url": "https://google.com",
        "label": "Optional",
        "description": "Optional"
    },
    {
        "url": "https://google.com",
        "label": "",
        "description": ""
    }
]`,
    ga: ''
}

export default config;