import React, { useState, useContext, useEffect } from 'react';
import md5 from 'md5';
import ImageFadeIn from "react-image-fade-in";
import defaultErrorMessage from '../utils/defaultErrorMessage';
import checkNested from '../utils/checkNested';
import miniAlert from '../utils/miniAlert';
import gtagE from '../utils/gtagE';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

// services
import serviceChangePassword from "../services/changePasswordService";

function Admin({ user }) {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const token = appState.token;

    const [isLoading, setIsLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    useEffect(() => {

        window.scrollTo(0, 0);
        gtagE('Profile');

    }, []);

    function handleSubmit(e) {

        e.preventDefault();

        if (
            oldPassword.length < 10 ||
            newPassword.length < 10 ||
            confirmNewPassword.length < 10
        ) return appDispatch({ type: "alertOpen", ico: "error", value: 'Password too short, minimum 10 characters' });

        if (newPassword !== confirmNewPassword) return appDispatch({ type: "alertOpen", ico: "error", value: 'Confirmation Password doesn\'t match' });

        if (newPassword === oldPassword) return appDispatch({ type: "alertOpen", ico: "error", value: '..why the same password?' });

        async function fetchData() {
            const request = serviceChangePassword.cancelToken();
            setIsLoading(true);
            try {
                await serviceChangePassword.changePassword({ token, oldPassword, newPassword, request });
                setTimeout(() => { setIsLoading(false); }, 500);
                // setUnsaved(false);
                miniAlert({
                    overflow: false,
                    autoremove: true,
                    size: 'medium',
                    text: 'Password updated!'
                });
                setOldPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
            } catch (e) {
                if (e.isCancel !== true) {
                    if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                    appDispatch({
                        type: "alertOpen",
                        ico: "error",
                        value: defaultErrorMessage(e)
                    });
                    setIsLoading(false);
                }
            }
        }
        fetchData();

    }

    return (
        <>

            <form className="form" onSubmit={handleSubmit}>

                <div className={`avatar` + (user && user.t && user.t > 1 ? ' avatar--king' : '')}>
                    <span><ImageFadeIn width={640} height={640} src={`https://gravatar.com/avatar/${md5(user.email)}?s=640&d=https://museobit.com/assets/images/icons/user.png`} /></span>
                </div>

                <label className="label-container disabled">
                    <input type="text" disabled readOnly value={user.username} />
                    <div className="label"><span>Username</span></div>
                </label>

                <label className="label-container disabled">
                    <input type="email" disabled readOnly value={user.email} />
                    <div className="label"><span>Email</span></div>
                </label>

                <span className="hr"></span>

                <label className="label-container">
                    <input type="password" onChange={(e) => setOldPassword(e.target.value)} value={oldPassword} />
                    <div className="label"><span>Current password</span></div>
                </label>

                <span className="hr"></span>

                <label className="label-container">
                    <input type="password" onChange={(e) => setNewPassword(e.target.value)} value={newPassword} />
                    <div className="label"><span>New password</span></div>
                </label>

                <label className="label-container">
                    <input type="password" onChange={(e) => setConfirmNewPassword(e.target.value)} value={confirmNewPassword} />
                    <div className="label"><span>Confirm password</span></div>
                </label>

                <span className="hr"></span>

                <button disabled={isLoading} type="submit" className={`btn btn--center btn--large btn--callToAction` + (isLoading ? ' btn--loading' : '')}>Save<span className="loading"></span></button>

            </form>


        </>
    );
}

export default Admin;
