import http from "./httpService";
import config from '../config';
import qs from 'querystring'

export function cancelToken() {
    return http.CancelToken.source();
}

export async function changePassword({ token, oldPassword, newPassword, request }) {
    return new Promise(
        async (resolve, reject) => {
            try {
                const response = await http({
                    method: 'post',
                    headers: { 'content-type': 'application/x-www-form-urlencoded', 'authorization': token },
                    url: config.backendUrl + config.endpoints.changePassword,
                    data: qs.stringify({ oldPassword, newPassword }),
                    cancelToken: request.token
                });
                if (response.data) return resolve(response.data);
                return reject();
            } catch (err) { return reject({ err, isCancel: http.isCancel(err) }); }
        }
    );
}

// eslint-disable-next-line
export default {
    cancelToken,
    changePassword
};
