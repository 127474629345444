import React from 'react';
import { Link } from "react-router-dom";
import tokenData from '../utils/tokenData';

function Header({ isLogged, state, dispatch, handleLogout }) {

    const user = state.token ? tokenData(state.token) : false;
    const username = user && user.username ? user.username : 'demo';

    return (
        <div data-nosnippet className="header">

            <div className="container">

                <Link to={"/"} className="logo"></Link>

                {!isLogged && (

                    <ul>
                        {/* <li><Link to={"@demo"}>Demo</Link></li> */}
                        {/* <li><a href="@demo">Demo</a></li> */}
                        <li><button onClick={(e) => { dispatch({ type: 'setPopupDemo', data: true }) }}>Demo</button></li>
                        <li className="login"><button onClick={(e) => { dispatch({ type: 'setPopupLogin', data: true }) }}>Log In</button></li>
                        <li><button onClick={(e) => { dispatch({ type: 'setPopupRegister', data: true }) }} className="btn">Sign Up</button></li>
                    </ul>

                )}

                {isLogged && (

                    <ul>
                        <li className="test"><a href={"/@" + username} className="cube-icon">
                            {/* <a className="cube-icon" href="@me"> */}
                            <div className="scene">
                                <div className="cube">
                                    <div className="cube__face cube__face--front"></div>
                                    <div className="cube__face cube__face--back"></div>
                                    <div className="cube__face cube__face--right"></div>
                                    <div className="cube__face cube__face--left"></div>
                                    <div className="cube__face cube__face--top"></div>
                                    <div className="cube__face cube__face--bottom"></div>
                                </div>
                            </div>
                            Gallery</a></li>
                        <li className="logout"><button onClick={handleLogout} className="btn">Logout</button></li>
                    </ul>

                )}

            </div>

        </div >
    );
}

export default Header;
