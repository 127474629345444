import React from 'react';
import moment from 'moment';
import _ from 'lodash'
import config from '../config';

function Billing({ membershipHistory }) {

    return (

        <>

            {
                (
                    !membershipHistory ||
                    (membershipHistory && !membershipHistory.length)
                )
                &&
                <div className="face-box">
                    <div className="face face--sad"></div>
                    <h3>No memberships yet</h3>
                </div>
            }

            {
                membershipHistory && membershipHistory.length > 0 &&

                <>

                    <span className="hr"></span>

                    <table>
                        <thead>
                            <tr>
                                <td>Date</td>
                                <td>Description</td>
                                <td>Amount</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                membershipHistory.slice(0).reverse().map((el, index) =>
                                    <React.Fragment key={index}>

                                        <tr>
                                            <td><small>from {moment.unix(el.timeStart).format('DD/MM/YYYY')} to {moment.unix(el.timeEnd).format('DD/MM/YYYY')}</small></td>
                                            <td>{_.startCase(_.toLower(el.accountTime))} - {el.label}</td>
                                            <td><small>{config.pricing.currencies[el.currency]}</small> {el.price}</td>
                                        </tr>

                                    </React.Fragment>
                                )
                            }
                        </tbody>
                    </table>

                    <span className="hr"></span>

                </>

            }

        </>

    );
}

export default Billing;