import React from 'react';
// import { Link } from "react-router-dom";

// context
// import StateContext from "../context/StateContext";
// import DispatchContext from "../context/DispatchContext";

function Section() {

    // const appState = useContext(StateContext);
    // const appDispatch = useContext(DispatchContext);

    return (
        <div className="section section--testimonials">

            <div className="">

                <div className="boxes">

                    {// eslint-disable-next-line
                        <a href="https://museobit.com/@vangogh" className="box">

                            <div className="testimonial-avatar"><img src="/assets/images/testimonials/vangogh.jpg" alt="" /></div>
                            <div className="quote">Finally a place where I can upload all my artworks without paying an arm and an.. ear.</div>
                            <h4>Vincent Van Gogh</h4>

                        </a>
                    }

                    {// eslint-disable-next-line
                        <a href="https://amedeolanci.com" className="box">

                            <div className="testimonial-avatar"><img src="/assets/images/testimonials/amedeolanci.jpg" alt="" /></div>
                            <div className="quote">A tribute to one of the best teacher of Florence Academy of Fine Arts.</div>
                            <h4>Amedeo Lanci</h4>

                        </a>
                    }

                    <div href="https://museobit.com/@vangogh" className="box">

                        <div className="testimonial-avatar"><img src="/assets/images/testimonials/frank.jpg" alt="" /></div>
                        <div className="quote">This framework give me the possibility to make a simple gallery in few minutes to promote my art.</div>
                        <h4>Frank</h4>

                    </div>

                </div>

            </div>

        </div>
    );
}

export default Section;