import React, { useEffect, useState, useContext } from 'react';
import checkNested from '../utils/checkNested';
import defaultErrorMessage from '../utils/defaultErrorMessage';
import tokenData from '../utils/tokenData';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

// services
import serviceRefreshUser from "../services/refreshUser";

// components
import Page from "./Page";
import SectionAdmin from "./SectionAdmin";
import SectionBg from "./SectionBg";

function Admin() {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const [isLoading, setIsLoading] = useState(false);

    const justLogged = appState.justLogged;
    const token = appState.token;
    const userDataToken = token ? tokenData(token) : false;

    const [userData, setUserData] = useState(userDataToken);

    useEffect(() => {

        const request = serviceRefreshUser.cancelToken();

        if (token && !justLogged) {

            function updateBase64Token(base64token) {

                const oldToken = token.split('.');
                const newToken = base64token + '.' + oldToken[1];
                setUserData(tokenData(newToken));
                appDispatch({ type: "setToken", data: newToken });

            }

            async function fetchData() {
                setIsLoading(true);
                try {
                    const response = await serviceRefreshUser.refresh({ token, request });
                    if (response.data.base64token) { updateBase64Token(response.data.base64token); }
                    setTimeout(() => { setIsLoading(false); }, 0);
                } catch (e) {
                    if (e.isCancel !== true) {
                        if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                        appDispatch({
                            type: "alertOpen",
                            ico: "error",
                            value: defaultErrorMessage(e)
                        });
                        setIsLoading(false);
                    }
                }
            }

            fetchData();

        }

        return () => { request.cancel(); }

    }, [token, justLogged, appDispatch])

    return (
        <Page>
            <SectionBg />
            <SectionAdmin
                userData={userData}
                isLoading={isLoading}
            />
        </Page>
    );
}

export default Admin;
