import React, { useEffect, useState, Suspense, useRef, useCallback } from 'react';
// import React, { useEffect, Suspense, useRef, useCallback, useState } from 'react';
import { useImmerReducer } from "use-immer";
// import { BrowserRouter, Switch, Route, Link, Redirect } from "react-router-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import confirmEmailInit from './utils/confirmEmailInit';
import config from './config';
import gtagE from './utils/gtagE';

// utils
import storage from './utils/storage'

// context
import StateContext from "./context/StateContext";
import DispatchContext from "./context/DispatchContext";

// components
import Admin from "./components/Admin";
import AlertReact from "./components/AlertReact";
import Cookie from "./components/Cookie";
// import CookiePolicy from "./components/CookiePolicy";
import DotsLoading from "./components/DotsLoading";
// import EmptyLoadingPage from "./components/EmptyLoadingPage";
import Footer from "./components/Footer";
// import Gallery3D from "./components/Gallery3D";
import Header from "./components/Header";
import Home from "./components/Home";
// import NotFound from "./components/NotFound";
// import Page from "./components/Page";
import PopupDemo from "./components/PopupDemo";
import PopupLogin from "./components/PopupLogin";
import PopupRegister from "./components/PopupRegister";
// import PrivacyPolicy from "./components/PrivacyPolicy";
// import Splash from "./components/Splash";
// import TermsOfUse from "./components/TermsOfUse";

import './assets/styles/styles.scss'
// import TagManager from 'react-gtm-module'
// import ReactGA from 'react-ga';

const CookiePolicy = React.lazy(() => import("./components/CookiePolicy"));
const PrivacyPolicy = React.lazy(() => import("./components/PrivacyPolicy"));
const TermsOfUse = React.lazy(() => import("./components/TermsOfUse"));

// ReactGA.initialize('G-KSEW29Z13Q');
// ReactGA.pageview(window.location.pathname + window.location.search);

// const tagManagerArgs = { gtmId: 'G-KSEW29Z13Q' }
// TagManager.initialize(tagManagerArgs)

function App() {

  const alertNodeRef = useRef(null);
  const cookieNodeRef = useRef(null);
  const popupDemoNodeRef = useRef(null);
  const popupLoginNodeRef = useRef(null);
  const popupRegisterNodeRef = useRef(null);

  const [confirmEmail, setConfirmEmail] = useState(false);

  const initialState = {
    alert: {
      show: false,
      text: '',
      ico: '',
      callback: false
    },
    randomBgImage: Math.floor(Math.random() * 5),
    // splash: window.location.hostname !== 'localhost' && !storage.get('splash'),
    popupDemo: false,
    popupLogin: false,
    popupRegister: false,
    token: storage.get('token'),
    justLogged: false,
    cookie: storage.get('cookie'),
    forceLogout: 0
  }

  function reducer(draft, action) {
    switch (action.type) {
      // case "removeSplash":
      //   draft.splash = false;
      //   storage.set('splash', true);
      //   return
      case "setPopupDemo":
        draft.popupDemo = action.data;
        gtagE('Demo');
        return
      case "setPopupLogin":
        draft.popupLogin = action.data;
        gtagE('Login');
        return
      case "setPopupRegister":
        draft.popupRegister = action.data;
        gtagE('SignUp');
        return
      case "alertClose":
        draft.alert.show = false;
        return
      case "alertOpen":
        draft.alert.show = true;
        draft.alert.ico = action.ico;
        draft.alert.text = action.value;
        draft.alert.callback = action.callback;
        return
      case "setToken":
        storage.set('token', action.data);
        draft.token = action.data;
        return
      case "setJustLogged":
        draft.justLogged = true;
        return
      case "setCookie":
        storage.set('cookie', true);
        draft.cookie = true;
        return
      case "forceLogout":
        draft.forceLogout++;
        return
      default: return
    }
  }

  const [state, dispatch] = useImmerReducer(reducer, initialState);

  useEffect(() => {

    if (window.location.pathname.startsWith(config.endpoints.confirmEmail)) {
      const url = window.location.pathname.split(config.endpoints.confirmEmail);
      if (url[1]) setConfirmEmail(url[1]);
    }

    if (window.location.pathname.startsWith(config.endpoints.unsubscribe)) {
      dispatch({
        type: "alertOpen",
        ico: "mail",
        value: 'Unsubscribe Successful!'
      });
    }

    function loading() {
      // setTimeout(function () {
      document.querySelector('html').classList.add('loaded');
      // window.scrollTo(0, 0);
      // }, 1000);
    }
    window.addEventListener('load', loading);
    setTimeout(function () {
      loading();
    }, 2000);

  }, [dispatch]);

  const isLogged = state.token;

  useEffect(() => {

    if (isLogged) {
      document.querySelector('html').classList.add('logged');
      gtagE('Dashboard');
    }
    else {
      document.querySelector('html').classList.remove('logged');
      gtagE('Home');
    }

  }, [isLogged]);

  const logout = useCallback(() => {

    dispatch({ type: 'setToken', data: false });
    // window.history.pushState(null, null, '/');

  }, [dispatch]);

  useEffect(() => {

    if (confirmEmail)
      confirmEmailInit({
        token: confirmEmail,
        dispatch
      });

  }, [confirmEmail, dispatch]);

  useEffect(() => {

    if (state.forceLogout) {
      logout();
      dispatch({
        type: "alertOpen",
        ico: "error",
        value: 'Session expired, please login again'
      });
    }

  }, [state.forceLogout, logout, dispatch]);

  function handleLogout() {

    dispatch({
      type: "alertOpen",
      ico: "error",
      value: 'Do you want to logout ?',
      callback: () => {

        logout();

      }
    });

  }

  useEffect(() => {

    if (
      state.popupLogin ||
      state.popupRegister
    ) {

      document.querySelector('html').classList.add('noscroll');

    } else {

      document.querySelector('html').classList.remove('noscroll');

    }

  }, [
    dispatch,
    state.popupLogin,
    state.popupRegister,
  ]);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <BrowserRouter>

          {/* {state.splash && <Splash dispatch={dispatch} />} */}

          {/* {!state.splash && */}

          <>

            <Header state={state} dispatch={dispatch} isLogged={isLogged} handleLogout={handleLogout} />

            <Suspense fallback={
              <DotsLoading />
              // <Page>
              //   <div className="dynamic">
              //     <div className="container">
              //       <DotsLoading />
              //     </div>
              //   </div>
              // </Page>
            }>
              {/* <Suspense fallback={<EmptyLoadingPage />}> */}
              <Switch>
                {/* <Route path="/"><Admin /></Route> */}
                {/* <Route path="/@:id"><Gallery3D /></Route> */}
                <Route path="/cookie-policy"><CookiePolicy /></Route>
                <Route path="/privacy-policy"><PrivacyPolicy /></Route>
                <Route path="/terms-of-use"><TermsOfUse /></Route>
                {isLogged && <Route path="/"><Admin /></Route>}
                {!isLogged && <Route path="/"><Home /></Route>}
                {/* {!isLogged && <div className="fb-customerchat" attribution="setup_tool" page_id="107184694796331"></div>} */}
                {/* <Route><NotFound /></Route> */}
              </Switch>
            </Suspense>

            <Footer state={state} dispatch={dispatch} isLogged={isLogged} handleLogout={handleLogout} />

            <CSSTransition nodeRef={popupDemoNodeRef} timeout={330} in={state.popupDemo} classNames="popup-demo" unmountOnExit>
              <div ref={popupDemoNodeRef} className="popup-demo">
                <PopupDemo state={state} dispatch={dispatch} />
              </div>
            </CSSTransition>

            <CSSTransition nodeRef={popupLoginNodeRef} timeout={330} in={state.popupLogin} classNames="popup-login" unmountOnExit>
              <div ref={popupLoginNodeRef} className="popup-login">
                <PopupLogin state={state} dispatch={dispatch} />
              </div>
            </CSSTransition>

            <CSSTransition nodeRef={popupRegisterNodeRef} timeout={330} in={state.popupRegister} classNames="popup-register" unmountOnExit>
              <div ref={popupRegisterNodeRef} className="popup-register">
                <PopupRegister state={state} dispatch={dispatch} />
              </div>
            </CSSTransition>

            <CSSTransition nodeRef={cookieNodeRef} timeout={330} in={!state.cookie} classNames="cookie" unmountOnExit>
              <Cookie ref={cookieNodeRef} />
            </CSSTransition>

          </>

          {/* } */}

          <CSSTransition
            in={state.alert.show}
            classNames="alert"
            nodeRef={alertNodeRef}
            timeout={330}
            unmountOnExit
          >
            <div ref={alertNodeRef}>
              <AlertReact />
            </div>
          </CSSTransition>

        </BrowserRouter>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export default App;
