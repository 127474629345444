import React, { useState, useContext } from 'react';
import defaultErrorMessage from '../utils/defaultErrorMessage';
import checkNested from '../utils/checkNested';
import miniAlert from '../utils/miniAlert';

// context
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";

// services
import serviceSaveGenericString from "../services/saveGenericStringService";

const safeList = [
    { id: 1, label: 'Safe', description: 'You share just teddy bears, unicorns, a lot of love and sweetness.' },
    { id: 2, label: 'Moderate', description: 'You share sensitive media.' },
    { id: 3, label: 'Restricted', description: 'You share not safe for work (NSFW) content & unsuitable content.' }
]

function Museum({ data, setData, unsaved, setUnsaved }) {

    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const [isLoading, setIsLoading] = useState(false);

    const [safe, setSafe] = useState(data.safetyLevel || 1);
    const [firstLoad, setFirstLoad] = useState(false);

    const token = appState.token;

    function handleSubmit(e) {

        e.preventDefault();

        async function fetchData() {
            const request = serviceSaveGenericString.cancelToken();
            setIsLoading(true);
            try {
                await serviceSaveGenericString.save({ token, content: safe, type: 'safetyLevel', request });
                setTimeout(() => { setIsLoading(false); }, 500);
                setUnsaved(false);

                const updateData = { ...data, safetyLevel: safe };
                setData(updateData);

                setFirstLoad(true);
                miniAlert({
                    overflow: false,
                    autoremove: true,
                    size: 'medium',
                    text: 'Saved!'
                });
            } catch (e) {
                if (e.isCancel !== true) {
                    if (checkNested(e, 'err', 'response', 'status') === 401) { appDispatch({ type: "forceLogout" }); }
                    appDispatch({
                        type: "alertOpen",
                        ico: "error",
                        value: defaultErrorMessage(e)
                    });
                    setIsLoading(false);
                }
            }
        }
        fetchData();

    }

    return (
        <form className="form" onSubmit={handleSubmit}>

            <label className="label-container">
                <select
                    defaultValue={safe}
                    onChange={(e) => {
                        setSafe(e.target.value);
                        setUnsaved(true);
                    }}
                >
                    {safeList.map((el, index) => <option key={index} value={el.id}>{el.label}</option>)}
                </select>
                <div className="label"><span>Safety level of your museum</span></div>
            </label>

            {/* <span className="hr"></span> */}

            <p>
                {/* <small> */}
                {safeList.find(el => (safe.toString() === el.id.toString())).description}
                {/* </small> */}
            </p>

            <span className="hr"></span>

            <button disabled={!unsaved && firstLoad} type="submit" className={'btn btn--center btn--large' + (unsaved || firstLoad ? ' btn--callToAction' : '') + (isLoading ? ' btn--loading' : '')}>Save<span className="loading"></span></button>

        </form>
    );
}

export default Museum;